main.main-wrapper > section.last-section:last-of-type{
	padding-bottom: 0;

	.bottom-image{
		width: 100vw;
		pointer-events: none;
		margin-bottom: -2px;
		margin-top: 30px;
		position: relative;

		@media (max-width: $b3){
			margin-top: 40px;
			margin-bottom: 0;
		}

		@media (max-width: 400px){
			max-width: unset;
			width: calc(100% + 4px);
			margin-left: -2px;
		}
	}

	@media (max-width: $b3){
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: $green;
		}
	}
}


footer{
	position: relative;
	background-color: $green;

	*{
		color: white;
	}

	.container{

		@media (min-width: $base-width + 1){
			max-width: calc(100% - 120px);
		}
	}

	.footer-top-outer{
		padding-top: 164px;
		padding-bottom: 78px;

		@media (max-width: $b3){
			padding-top: 75px;
			padding-bottom: 93px;
			text-align: center;
		}

		.logo-con{
			
			@media (max-width: $b2){
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		#footer-logo{
			display: block;
			height: auto;
			max-width: 100%;
			margin: 0 0 3.9em;
			transition: $time;
			z-index: 1;
			width: 195.94px;
			z-index: 50;
			transition: 0s;

			@media (max-width: $b2){
				margin: 0 auto 1.6em;
			}

			svg,
			img{
				display: block;
				width: 100%;
			}
		}

		address{
			width: 220px;

			@media (max-width: $b2){
				text-align: center;
				margin: 0 auto 2.4em;
			}
		}

		.social-menu{
			padding-top: 1.3em;

			@media (max-width: $b3){
				padding-top: 0;
			}

			a{

				&:hover,
				&:focus-visible{
					color: $green-light;

					*{
						color: $green-light!important;
					}
				}
			}
		}

		.fr{
			width: 218px;

			@media (max-width: $b2){
				display: none;
			}
		}

		.links-con{
			padding: 0;
			display: flex;
			flex-wrap: wrap;
		}

		h6{
			font-weight: 700;
			color: $green-light;
			margin-bottom: 1.5em;
			min-height: 1.1875em;

			@media (max-width: $b3){
				margin-bottom: 1.2em;
			}
		}

		p{
			margin-bottom: .9em;
			margin-top: -.65em;

			@media (max-width: $b3){
				margin-top: -.25em;
			}
		}

		p + a{
			font-weight: 700;
			color: $green-light;
			@include font-size(32);
			font-family: $alt-font;

			&:hover,
			&:focus-visible{
				color: $white;
			}
			
			@media screen and (max-width: 1400px) {
				@include font-size(20);
			}
		}

		.footer-menu{
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto 2.8em;
			@include font-size(20);
			line-height: 1;

			@media (max-width: $b3){
				margin: 0 auto 1.25em;
			}

			li{
				@include font-size(20);
				line-height: 1;
				margin-bottom: 26px;
				width: 100%;

				&:first-of-type{
					padding-left: 0;
				}
				
				a{
					@include font-size(20);
					line-height: 1;
					font-weight: 400;
					text-decoration: none;
					color: $white;
					display: block;

					&:hover,
					&:focus-visible{
						color: $green-light!important;
					}
				}
			}
		}

		.col-md-8{

			@media (max-width: $b3){
				
				h6{
					display: none;
				}

				p{
					margin-top: 1.8em;
				}
			}
		}

		.links-con{

			@media (min-width: 1079px){	
				flex: 0 0 55%;
				max-width: 55%;
			}

			@media (max-width: 1079px){
				
				@media (min-width: 768px){
					flex: 0 0 75%;
					max-width: 75%;
				}
			}

			@media (max-width: $b2){
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.support-con{

			@media screen and (max-width: 1200px) {

				.btn {
					min-width: 100%;
				}
			}

			@media (min-width: 1079px){	
				flex: 0 0 20%;
				max-width: 20%;
				padding-left: 30px;
			}

			@media (max-width: 1079px){
				flex: 0 0 100%;
				max-width: 100%;
				text-align: center;
				display: flex;
				justify-content: space-between;
				margin-top: 2.5em;

				.support-con--left {
					width: calc(50% - 10px);

					.side {
						margin-top: 0;
					}
				}

				.support-con--right {
					width: calc(50% - 10px);
				}

				.btn{
					width: auto;
					min-width: 180px;

					@media (max-width: $b3){
						width: 100%;
					}
				}
			}

			@media (max-width: 767px) {
				flex-direction: column;
				justify-content: flex-start;

				.support-con--left {
					width: 100%;
				}

				.support-con--right {
					width: 100%;
				}
			}
		}

		p.side{
			@include font-size(24);
			@include line-height(24,32);
			font-weight: 700;
			font-family: $alt-font;
			margin-top: -.1em;
			width: 250px;
			margin-bottom: 1.65em;

			@media (max-width: 1079px){
				@include font-size(32);
				@include line-height(32,38);
				width: 100%;
				margin-top: 1.5em;
				margin-bottom: 1.05em;
			}
		}

		.btn{
			width: 100%;
			margin-bottom: 3.5em;

			@media (max-width: $b3){
				margin-bottom: 2.6em;
			}
		}

		.social-menu{

			@media (max-width: 1079px){
				justify-content: center;

				a{
					@include font-size(42);
					line-height: 42px;
					min-width: 42px;
					margin: 0 18px;

					*{
						@include font-size(42);
						line-height: 42px;
						min-width: 42px;
					}
				}
			}
		}
	}

	.footer-bottom-outer{
		padding: 49px 0 151px;
		width: 100%;

		@media (max-width: $b2){
			padding: 40px 0 205px;
		}

		.row{
			position: relative;
	
			&::before{
				content: '';
				position: absolute;
				top: -49px;
				right: 15.5px;
				left: 15.5px;
				height: 1px;
				background-color: white;

				@media (max-width: $b3){
					right: 24px;
					left: 24px;
				}
			}
		}

		.col-md-4,
		.col-md-8{

			@media (max-width: $b2){
				flex: 0 0 100%;
				max-width: 100%;
				text-align: center;
			}
		}

		.copy{
			@include font-size(16);
			@include line-height(16,22);
			color: rgba(white, .5);

			@media (max-width: $b2){
				@include font-size(20);
				@include line-height(20,27);
				margin-bottom: 2.4em;
			}

			@media (max-width: $b2){
				
				&:before{
					content: '';
					background-image: url(../images/fr.png);
					background-repeat: no-repeat;
					width: 378px;
					height: 115.9px;
					display: block;
					background-size: 100% auto;
					margin: 0 auto;
					margin-bottom: 1.7em;
				}
			}

			@media (max-width: 428px){
				
				&:before{
					padding-top: 30.5%;
				}
			}
		
			a{
				color: white;
				text-decoration: underline;

				&:hover,
				&:focus-visible{
					color: $green;
				}
			}
		}

		.small-menu{
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			@include font-size(16);
			@include line-height(16,22);
			justify-content: flex-end;

			@media (max-width: $b2){
				justify-content: space-between;
				@include font-size(20);
				@include line-height(20,27);
			}

			li{
				@include font-size(16);
				@include line-height(16,22);

				@media (max-width: $b2){
					@include font-size(20);
					@include line-height(20,27);
				}
				
				+ li{
					margin-left: 40px;
				}
				
				a{
					@include font-size(16);
					@include line-height(16,22);
					letter-spacing: .02em;
					font-weight: 600;
					text-decoration: underline;
					color: $white;
					display: block;

					@media (max-width: $b2){
						@include font-size(20);
						@include line-height(20,27);
					}

					&:hover,
					&:focus-visible{
						color: $green-light!important;
					}
				}
			}
		}
		
	}

	.cookies-preferences {
		display: inline-block;
		margin-top: 15px;
		font-size: 1.6rem;
		margin-bottom: 30px;
		opacity: 0.5;
		transition: opacity 120ms;

		@media screen and (min-width: 992px) {
			margin-top: 15px;
			margin-bottom: 0;
			opacity: 0.85;
		}

		&:hover {
			opacity: 1;
		}
	}
}