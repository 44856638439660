//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: relative;
	top: 0;
	left: 0;
	transition: $time;
	background-color: transparent;

	// .home &{
	// 	background-color: transparent;
	// }

	@media (max-width: $b2){
		z-index: 111;
		margin-bottom: -1px;
	}

	// .scroll-down &,
	// .scroll-up &{

	// 	ul.main-menu.main-menu li > a{
	// 		color: $colour;

	// 		@media (max-width: $b2){
	// 			color: white;
	// 		}
	// 	}
	// }

	// .scroll-down &{
	// 	transform: translateY(-100%);
	// 	pointer-events: none;
	// }

	// .scroll-up &{
	// 	transform: translateY(0%);
	// 	background-color: white;
	// 	box-shadow: 0 10px 15px rgba(black, .1);

	// 	@media (min-width: $b2 + 1){
			
	// 		#site-logo{
	// 			margin-top: 30.89px;
	// 			margin-bottom: 30.89px;
	// 			width: 79px;
	// 			overflow: hidden;

	// 			img{
	// 				max-width: 280px;
	// 				width: 280px;
	// 			}
	// 		}
	// 	}
	// }
}

.quick-exit{
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	right: 74px;
	background-color: $pink;
	z-index: 222;
	color: white;
	display: flex;
	align-items: center;
	@include font-size(28);
	@include line-height(28,33);
	padding: 24px 39px;
	font-weight: 700;
	font-family: $alt-font;
	border-radius: 50px;
	box-shadow: 0 0 30px rgba(white, .35);

	@media (max-width: 1480px){
		right: 15.5px;
	}

	@media (max-width: $b2){
		width: 117px;
		border-radius: 100px 0 0 100px;
		bottom: 0;
		top: auto;
		transform: none;
		right: 0;
		min-height: 118px;
		text-align: center;
		padding: 10px 10px;
	}

	&:hover,
	&:focus-visible{
		color: white;

		span{
			max-width: 52.055px;
			margin-left: 14px;
		}
	}

	span{
		max-width: 0;
		overflow: hidden;

		@media (max-width: $b2){
			display: none;
		}

		svg{
			width: 52.055px;
			min-width: 52.055px;
			max-width: 52.055px;
		}
	}
}

#site-logo{
	display: block;
	height: auto;
	max-width: 100%;
	margin: 0;
	transition: $time;
	z-index: 1;
	width: 172.34px;
	transition: 0s;
	overflow: hidden;

	@media (max-width: 1480px){
		width: 146.67px;
	}

	img{
		display: block;
		width: 100%;
	}
}

html.search-open{
	overflow: hidden;
}

.menu-top-outer{
	transition: $time;
	padding-top: 32px;
	padding-bottom: 32px;

	@media (max-width: $b2){
		padding-top: 54px;
		padding-bottom: 10px;
	}
	
	.container{
		max-width: 100%;

		@media (min-width: 1480px + 1){
			max-width: calc(100% - 152px);
		}
	}
 	
	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.right,
	.left{
		display: flex;
		align-items: center;
	}

	.left{

		@media (max-width: $b2){
			position: absolute;
			width: 146.67px;
			left: 50%;
			transform: translate(-50%);
			top: 21px;
			z-index: 2;
		}
	}

	.right{
		width: calc(100% - 180px);
		flex-wrap: wrap;
		align-items: center;
		margin-top: -10px;

		@media (max-width: 1480px){
			width: calc(100% - 146.67px);
		}

		@media (max-width: $b2){
			width: 100%;
			margin-top: 0;
		}

		.top,
		.bottom{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 21px 0;
		}

		.top{
			width: 100%;
			justify-content: flex-end;
			
			@media (max-width: $b2){
				min-height: 173px;
				align-items: flex-start;
			}
		}

		.bottom{
			width: 100%;
			justify-content: flex-end;

			@media (max-width: $b2){
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				background-color: $green-light;
				padding: 84px 24px 35px;
				z-index: 2;
				flex-direction: column;
				display: none;
				
				.menu-open &{
					display: flex;
				}
			}

			.btn{
				margin-right: 5px;

				@media (max-width: $b2){
					margin: 1em auto 0;
				}
			}
		}
	}

	.helpline{
		@include font-size(24);
		font-weight: 500;
		font-family: $alt-font;
		margin-right: 50px;

		@media (max-width: 1480px){
			margin-right: 20px;
		}

		@media (max-width: $b2){
			position: absolute;
			top: -54px;
			left: 0;
			right: 0;
			background-color: $green;
			color: white;
			text-align: center;
			margin-right: 0;
			line-height: 54px;
		}

		a{
			font-weight: 700;
			color: $green;
			
			@media (max-width: $b2){
				color: $green-light;
			}

			&:hover,
			&:focus-visible{
				color: $pink;
			}
		}
	}

	.language{
		display: flex;
		align-items: center;

		@media (max-width: $b2){
			display: none;
		}

		.lab{
			@include font-size(18);
			@include line-height(18,21);
			font-weight: 500;
			font-family: $alt-font;
			margin-right: 23px;
			margin-left: 48px;

			@media (max-width: 1480px){
				margin-right: 10px;
				margin-left: 10px;
				display: none;
			}
		}
		
		#google_translate_element{
			width: 218px;

			@media (max-width: 1480px){
				width: 180px;
				margin-left: 20px;
			}
		}

		.goog-te-gadget{
			position: relative;
			height: 47.99px;
			text-align: right;
			padding-right: 99px;

			> span{
				position: absolute;
				top: calc(100% + 1.5px);
				right: 0px;

				a{
					display: flex;
					align-items: baseline;
					line-height: 1;

					img{
						transform: translateY(6px);
						padding-right: 2px!important;
					}
				}
			}
		}

		select{
			width: calc(100% + 99px);
			max-width: calc(100% + 99px);
			height: 47.99px;
			line-height: 43.99px;
			border-color: #434343;
			color: #434343;
			@include font-size(14);
			margin: 0;
			background-image: url(../images/select-icon-alt.svg);
		}
	}

	.search-con{
		position: relative;
		width: 418px;

		@media (max-width: 1480px){
			width: 280px;
		}

		@media (max-width: $b2){
			position: absolute;
			top: 19px;
			left: 24px;
			height: 47.65px;
			width: calc(100% - 24px - 75px);
			z-index: 2;
			pointer-events: none;

			&.open{
				background-color: $green-light;
				position: fixed;
				z-index: 1111;
				top: 0;
				left: 0;
				width: 100%;
				height: 100vh;
				padding: 138px 24px;
				pointer-events: auto;
				display: flex;
				align-items: center;

				form{
					width: 100%;
				}

				i{
					left: 24px;
				}

				input{
					display: block;
				}

				.search-con-close {
					opacity: 1;
				}
			}

			*{
				pointer-events: auto;
			}
		}

		::-webkit-input-placeholder {
			font-family: $alt-font;
			@include font-size(18);
			color: rgba($green, .5);
			font-weight: 500;
		}
		 
		:-moz-placeholder { /* Firefox 18- */
			font-family: $alt-font;
			@include font-size(18);
			color: rgba($green, .5);
			font-weight: 500;
		}
		
		::-moz-placeholder {  /* Firefox 19+ */
			font-family: $alt-font;
			@include font-size(18);
			color: rgba($green, .5);
			font-weight: 500;
		}
		
		:-ms-input-placeholder {
			font-family: $alt-font;
			@include font-size(18);
			color: rgba($green, .5);
			font-weight: 500;
		}

		input{
			margin: 0;
			font-family: $alt-font;
			@include font-size(18);
			color: $green;
			font-weight: 500;
			border-radius: 10px;
			background-color: white;
			padding: 12.3px 20px 12.3px 48px;

			@media (max-width: $b2){
				display: none;
				margin-left: 40px;
				width: calc(100% - 40px);
				padding: 12.3px 12.3px 12.3px 12.3px;

				&.open{
					display: block;
				}
			}
		}

		i{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 20px;

			@media (max-width: $b2){
				left: 0;
				width: 34.06px;
				cursor: pointer;

				svg{
					width: 100%;
				}
			}
			
			svg{

				*{
					fill: $green;
					transition: $time;
				}
			}
		}

		&-close {
			display: none;

			@media (max-width: $b2) {
				display: block;
				position: absolute;
				top: 20px;
				right: 20px;
				background: transparent;
				border: none;
				padding: 0;
				margin: 0;
				@include font-size(40);
				font-weight: 700;
				color: $green;
				opacity: 0;
			}
		}
	}
}

.sticky-nav{
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	bottom: 67px;
	// background-color: $orange;
	z-index: 111;
	box-shadow: 0 0 30px rgba(black, .16);
	border-radius: 32px;
	width: 982.18px;
	max-width: calc(100vw - 40px);

	@media (max-width: $b2){
		bottom: 0;
		left: 0;
		width: 100%;
		max-width: 100%;
		transform: none;
	}

	.sticky-menu{
		margin: 0;
		display: flex;
		@include font-size(20);
		line-height: 1.2;

		@media (max-width: $b2){
			@include font-size(16);
			@include line-height(16,18);
			background-color: $orange;
			align-items: center;
			min-height: 118px;
		}

		li{
			margin: 0;
			@include font-size(20);
			line-height: 1.2;
			display: block;

			@media (max-width: $b2){
				@include font-size(16);
				@include line-height(16,18);
				flex: 1 1 auto;
			}

			&:first-of-type{
				border-radius: 32px 0 0 32px;

				> a{
					padding-left: 96px;
					background-position: 32px 50%;
					border-radius: 32px 0 0 32px;
				}
			}

			&:last-of-type:last-of-type{
				border-radius: 0 32px 32px 0;

				> a{
					padding-right: 95px;
					background-position: right 32px top 50%;
					border-radius: 0 32px 32px 0;
				}
			}

			&.young{

				> a{
					background-image: url(../images/sticky-1.svg);

					&:hover,
					&:focus-visible{
						background-image: url(../images/sticky-1-pink.svg);
					}
				}
			}

			&.parent{

				> a{
					background-image: url(../images/sticky-2.svg);

					&:hover,
					&:focus-visible{
						background-image: url(../images/sticky-2-pink.svg);
					}
				}
			}

			&.children{

				@media (max-width: $b2){
					margin-right: 117px;
				}

				> a{
					background-image: url(../images/sticky-3.svg);

					&:hover,
					&:focus-visible{
						background-image: url(../images/sticky-3-pink.svg);
					}
				}
			}

			&.support-us{

				@media (max-width: $b2){
					display: none;
				}

				> a{
					background-image: url(../images/sticky-4.svg);
					padding: 20px 64px 20px 20px;
					background-position: right 10px top 50%;
					background-color: $pink;
					background-size: 29.497px auto;

					&:hover,
					&:focus-visible{
						color: white;
						//background-size: 34.497px auto;
						animation: pulseH $time*4 0s infinite forwards;
					}

					@keyframes pulseH {
						0%,
						100%{
							background-size: 29.497px auto;
						}

						50%{
							background-size: 34.497px auto;
						}
					}
				}
			}

			a{
				background-color: $orange;
				@include font-size(20);
				line-height: 1.2;
				font-weight: 700;
				font-family: $alt-font;
				text-decoration: none;
				color: white;
				padding: 20px 20px 20px 64px;
				display: block;
				background-image: url(../images/.svg);
				background-repeat: no-repeat;
				background-position: 10px 50%;
				transition: $time;

				@media (max-width: $b2){
					@include font-size(16);
					@include line-height(16,18);
					background-position: 50% 0px!important;
					padding: 41px 5px 2px!important;
					border-radius: 0!important;
					text-align: center;
				}

				&:hover,
				&:focus-visible{
					color: $pink;
				}
			}

		}
	}
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;

	@media (min-width: $b2 + 1){
		margin: 0 auto;
	}

	.hide-menu &{
		display: none;
	}
}

@media (min-width: $b2 + 1){

	#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

		> a{
			color: $green;
		}
	}
}

ul.main-menu.main-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	position: relative;
	
	@media (max-width: $b2){
		flex-direction: column;
		align-items: center;
	}

	li{
		@include font-size(0);
		line-height: 1;
		position: static;
		display: flex;
		flex-direction: column;
		text-align: left;
		margin-right: 80px;

		@media (max-width: 1480px){
			margin-right: 20px;
		}

		@media (max-width: $b2){
			margin-right: 0;
			margin-bottom: 10px;
		}

		&:last-of-type{
			margin-right: 7.664px;
		}

		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		@media (min-width: $b2 + 1){

			&:hover{

				> a{
					color: $green;
				}
			}
		}

		&.menu-item-has-children {
			background-repeat: no-repeat;
			position: relative;
			flex-wrap: wrap;
			align-items: center;

			&::after {
				position: absolute;
				top: 0;
				left: 100%;
			    display: inline-block;
			    font-family: "FontAwesome";
			    content: "\f107";
			    color: #000;
			    font-size: 16px;
			    padding: 5px;

			    @include bp-lg {
				    padding: 8px;
				    left: 50%;
				    top: auto;
				    bottom: -6px;
				    transform: translateX(-50%);
				    transition: color $time;
			    }
			}

			> .sub-menu {
				overflow: hidden;
				max-height: 0px;
				transition: max-height 200ms;
				text-align: center;
				margin: 0;
				padding: 0;
				list-style: none;

				@include bp-lg {
					position: absolute;
					max-height: initial;
					opacity: 0;
					visibility: hidden;
					transition: opacity 200ms, visibility 200ms;
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translateX(-50%);
					white-space: nowrap;
					color: #FFF;
					border-radius: 10px;
					max-width: 300%;
				}

				> li {
					margin-right: 0;

					@include bp-lg {
						background-color: $green;

						&:hover {
							background-color: $green-light; 
						}
					}

					&:first-child {
						padding-top: 10px;

						@include bp-lg {
							padding-top: inherit;
							border-top-left-radius: 10px;
							border-top-right-radius: 10px;
						}
					}

					&:last-child {
						margin-bottom: 0;

						@include bp-lg {
							border-bottom-left-radius: 10px;
							border-bottom-right-radius: 10px;
						}
					}

					> a {
						font-weight: 500;
						font-size: 14px;
						text-align: center;
						padding: 0;

						@include bp-lg {
							padding: 9px 15px;
							font-size: 18px;
							color: #FFF;
						}
					}

					&.current-menu-item:not(.menu-item-object-custom) > a {
						@include bp-lg {
							color: $yellow !important;
						}
					}
				}
			}

			&.open{
				> .sub-menu{
					max-height: 400px;
				}
			}


			@include bp-lg {
				&:hover {
					> .sub-menu {
						visibility: visible;
						opacity: 1;
					}

					&::after {
						color: $green;
					}
				}
			}
		}

		> a{
			@include font-size(24);
			@include line-height(24,28);
			width: auto;
			color: $black;
			text-decoration: none!important;
			font-weight: 700;
			position: relative;
			font-family: $alt-font;
			position: relative;
			padding: 17.5px 0;
			overflow: hidden;
			z-index: 2;

			@media (min-width: $b2 + 1){

				&:hover,
				&:focus-visible{
					color: $green;
				}
			}

			@media (max-width: $b2){
				padding: 0;
			}
		}
	}
}


@media (min-width: $b2 + 1){
	
	.search.search.search.search.search.search.search.search.search,
	.error404.error404.error404.error404.error404.error404.error404.error404.error404.error404{

		#menu-item-234{

			> a{
				color: $black;

				&:hover,
				&:focus-visible{
					color: $green;
				}
			}
		}

	}
}
