//!!BUTTON
button:focus-visible,
.btn:focus-visible{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -20px;
	max-width: calc(100% + 20px + 20px);
	flex-wrap: wrap;

	.btn{
		margin: 0 20px 12px;

		&:first-of-type:last-of-type{
			margin-bottom: 0;
		}

		@media (min-width: $b3 + 1){
			
			&.big:first-of-type:not(:last-of-type){
				margin-left: -8px;
			}
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
	
}

.btn, %btn{
	display: inline-flex;
	align-items: center;
	padding: 11.5px 10px;
	font-size: 24px!important;
	font-size: 2.4rem!important;
	text-decoration: none!important;
	line-height: 1.5em;
	border: 2px solid $pink;
	color: $white!important;
	font-weight: 700;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	font-family: $alt-font;
	position: relative;
	border-radius: 10px;
	transition: $time;
	background-color: $pink;
	min-width: 180px;
	justify-content: center;
	box-shadow: 5px 7px 0 $yellow;

	&.big{
		box-shadow: 13px 9px 0 $yellow;
	}

	svg{
		margin-left: 12px;

		*{
			fill: white!important;
		}
	}
	
	&:hover,
	&:focus-visible{
		outline: none;
		background-color: $yellow;
		border-color: $yellow;
		color: $pink!important;
		box-shadow: 5px 7px 0 $pink;

		svg{

			*{
				fill: $pink!important;
			}
		}
	}

	&.disabled,
	&:disabled{
		opacity: .5;
		pointer-events: none;
	}
}

.btn{

	&.pink-white{
		background-color: $pink;
		border-color: $pink;
		color: $white!important;
		box-shadow: 5px 7px 0 $white;

		&.big{
			box-shadow: 13px 9px 0 $white;

			&:hover,
			&:focus-visible{
				box-shadow: 13px 9px 0 $pink;
			}
		}

		&:hover,
		&:focus-visible{
			background-color: $white;
			border-color: $white;
			color: $pink!important;
			box-shadow: 5px 7px 0 $pink;
		}
	}

	&.green-light{
		background-color: $green-light;
		border-color: $green-light;
		color: $green!important;
		box-shadow: 5px 7px 0 $green;

		svg{

			*{
				fill: $green!important;
			}
		}

		&.big{
			box-shadow: 13px 9px 0 $green;

			&:hover,
			&:focus-visible{
				box-shadow: 13px 9px 0 $green-light;
			}
		}

		&:hover,
		&:focus-visible{
			background-color: $green;
			border-color: $green;
			color: $green-light!important;
			box-shadow: 5px 7px 0 $green-light;

			svg{

				*{
					fill: $green-light!important;
				}
			}
		}
	}

	&.green-white{
		background-color: $green-light;
		border-color: $green-light;
		color: $green!important;
		box-shadow: 5px 7px 0 $white;

		&.big{
			box-shadow: 13px 9px 0 $white;

			&:hover,
			&:focus-visible{
				box-shadow: 13px 9px 0 $white;
			}
		}

		&:hover,
		&:focus-visible{
			background-color: $green;
			border-color: $green;
			color: $green-light!important;
			box-shadow: 5px 7px 0 $white;
		}
	}

	&.green{
		background-color: $green;
		border-color: $green;
		color: $green-light!important;
		box-shadow: 5px 7px 0 $green-light;

		&.big{
			box-shadow: 13px 9px 0 $green-light;

			&:hover,
			&:focus-visible{
				box-shadow: 13px 9px 0 $green;
			}
		}

		&:hover,
		&:focus-visible{
			background-color: $green-light;
			border-color: $green-light;
			color: $green!important;
			box-shadow: 5px 7px 0 $green;
		}
	}

	&.big{
		padding: 30px 31px;
		border-radius: 21px;
		line-height: 1.2;
	}

	&.small{
		padding: 6px 10px;
		font-size: 11px!important;
		font-size: 1.1rem!important;
	}
}

