@use "sass:math";
//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'buttons';
@import 'forms';
@import 'swiper';
@import 'fancybox';
//@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0px;
	width: 100%;
	overflow: hidden;
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

// .breadcrumb-con{
// 	@include font-size(18);
// 	line-height: 1.2em;
// 	margin-bottom: 50px;
// 	color: $colour;
// 	font-weight: 400;
// 	display: flex;
// 	justify-content: flex-start;

// 	@media (max-width: $b3){
// 		margin-bottom: 30px;
// 	}

// 	span{
// 		color: white;
// 		@include font-size(18);
// 		line-height: 1.2em;
// 		display: inline-block;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}
// 	}

// 	a{
// 		@include font-size(18);
// 		letter-spacing: .015em;
// 		line-height: 1.2em;
// 		text-decoration: none;
// 		@include inline;
// 		vertical-align: middle;
// 		color: $red;
// 		font-weight: 400;
// 		margin-right: .2em;
// 		position: relative;
// 		display: inline-block;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}

// 		&:after{
// 			content: '/';
// 			display: inline-block;
// 			margin-left: .4em;
// 			color: $colour;
// 		}


// 		&:hover,
// 		&:focus-visible{
// 			color: $red;
// 			text-decoration: none;
// 		}
// 	}

// 	> a{
// 		margin-left: 0;
// 	}

// 	.breadcrumb_last{
// 		@include font-size(18);
// 		letter-spacing: .015em;
// 		line-height: 1.2em;
// 		color: $colour;
// 		text-decoration: none;
// 		@include inline;
// 		vertical-align: middle;
// 		font-weight: 400;
// 		position: relative;

// 		@media (max-width: $b3){
// 			@include font-size(12);
// 		}
// 	}
// }

//
//!! BREADCRUM END
//

// .pagination-con{
// 	display: flex;
// 	justify-content: flex-start;
// 	align-items: flex-start;
// }

.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 4.25em;

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		margin: 0 7px;
		@include font-size(15);
		line-height: 45px;
		position: relative;
		z-index: 1;


		&:not(.prev):not(.next){

			&.active{
				pointer-events: none;

				a{
					color: $green;
					background-color: $green-light;
					box-shadow: 3px 3px 0 $green;
					transform: rotate(6deg)
				}
			}

			span,
			a{
				@include font-size(35);
				line-height: 45px;
				width: 45px;
				height: 45px;
				background-color: $green;
				display: block;
				color: $green-light;
				font-weight: 400;
				padding: 0;
				font-family: $alt-font2;
				border-radius: 10px;
				box-shadow: 3px 3px 0 $green-light;

				&:hover,
				&:focus-visible{
					text-decoration: none;
					color: $green;
					background-color: $green-light;
					box-shadow: 3px 3px 0 $green;
				}
			}


			&.ellipse{
				color: $colour;
				font-weight: 600;
			}
		}

		&.next,
		&.prev{

			a{
				@include font-size(0);
				line-height: 45px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $white;
				text-decoration: none;
				background-position: 50% 37%;
				background-repeat: no-repeat;
				width: 45px;
				height: 45px;
				border-radius: 10px;

				&:hover,
				&:focus-visible{
					text-decoration: none;
				}
			}
		}

		&.next{
			
			a{
				background-image: url(../images/arrow-right.svg);
				background-position: 52% 50%;

				&:hover {
					background-image: url(../images/arrow-right-lime.svg);
				}
			}
		}

		&.prev{
			
			a{
				background-image: url(../images/arrow-left.svg);
				background-position: 48% 50%;

				&:hover {
					background-image: url(../images/arrow-left-lime.svg);
				}
			}
		}
	}
}

//
//!!SOCIAL MENU START
//
$social-size:		35;
$social-addition:	0;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$instagram:		#8a3ab9;
$youtube:		#bb0000;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	a{
		@include font-size($social-size);
		line-height: $social-size + $social-addition + px;
		height:  $social-size + $social-addition + px;
		min-width: $social-size + $social-addition + px;
		border-radius: $social-size + $social-addition + px;
		display: block;
		text-align: center;
		color: $white;
		text-decoration: none;
		padding: 0;
		font-weight: 100;
		margin: 0 10px;

		@media screen and (max-width: 1400px) {
			height: 25px;
			min-width: 25px;
		}

		@media screen and (max-width: 1079px) {
			height:  $social-size + $social-addition + px;
			min-width: $social-size + $social-addition + px;
		}

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}
		
		&:hover,
		&:focus-visible{
			
			i{
				color: $white!important;
			}
		}

		i{
			@include font-size($social-size);
			line-height: $social-size + $social-addition + px;
			min-width: $social-size + $social-addition + px;
			display: block;
			transition: $time;

			@media screen and (max-width: 1400px) {
				@include font-size(25);
				line-height: 1.2;
				min-width: 25px;
			}
		}

		&.facebook{

			&:hover,
			&:focus-visible{

				i{
					color: $facebook!important;
				}
			}
		}

	
		&.twitter{

			&:hover,
			&:focus-visible{

				i{
					color: $twitter!important;
				}
			}
		}

		&.linkedin{

			&:hover,
			&:focus-visible{

				i{
					color: $linkedin!important;
				}
			}
		}

		&.pinterest{

			&:hover,
			&:focus-visible{

				i{
					color: $pinterest!important;
				}
			}
		}

		&.instagram{

			&:hover,
			&:focus-visible{

				i{
					color: $instagram!important;
				}
			}
		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .5s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(50px);
	will-change: transform;

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}

	&.delay{

		&.animate{
			transition-delay: .5s;
		}
	}

	&.right{
		transform: translateX(100px);

		&.animate{
			transform: translateX(0);
		}
	}

	&.left{
		transform: translateX(-100px);

		&.animate{
			transform: translateX(0);
		}
	}
}

.small-text.small-text.small-text{
	color: $green;
	@include font-size(47);
	@include line-height(47,55);
	font-family: $alt-font2;
	transform: rotate(-6deg);
	background-image: url(../images/line.svg);
	background-repeat: no-repeat;
	background-position: 0% 93%;
	min-width: 186.7146px;
	text-align: left;
}

.hero-with-links-section{
	padding-top: 16px;
	padding-bottom: 150px;
	min-height: 750px;
	position: relative;

	@media (max-width: $b2){
		padding-bottom: 150px;
		min-height: 0px;
	}

	@media (max-width: $b3){
		padding-top: 36px;
		padding-bottom: 0px;
		min-height: 0;
	}

	.side-leaf,
	.side-house{
		position: absolute;
	}

	.side-leaf{
		top: 41.8%;
		left: 0.8%;

		@media (max-width: 1600px){
			left: -9.2%;
			width: 27.431%;
		}

		@media (max-width: $b3){
			top: 60.1%;
			left: -21.2%;
			width: 62.431%;
		}
	}

	.side-house{
		top: 11.4%;
		right: -21.1%;

		@media (max-width: 1600px){
			right: -35.3%;
			width: 52.846%;
		}

		@media (max-width: $b3){
			top: 20.1%;
			right: -85.8%;
			width: 117.246%;
			max-width: 117.246%;
		}
	}

	.col-12{
		position: relative;
		z-index: 2;
	}
	
	.text{
		text-align: center;

		.text-con{
			margin: 0 auto;
			width: 800px;
			position: relative;

			.small-text{
				position: absolute;
				left: -3px;
				bottom: -29px;

				@media (max-width: $b3){
					left: 50%;
					bottom: -74px;
					transform: translateX(-50%) rotate(-6deg);
				}
			}
		}
	}

	.btn-con{
		justify-content: center;
		margin-top: 3em;

		@media (max-width: $b3){
			margin-top: 6.3em;
		}

		.btn{
			width: 205px;

			@media (max-width: $b3){
				width: 100%;
				font-size: 32px !important;
				font-size: 3.2rem !important;
				margin-bottom: 27px;
			}
		}
	}
}

.hero-just-text-section{
	padding-top: 16px;
	padding-bottom: 150px;
	min-height: 645px;
	position: relative;

	.search &{
		min-height: 300px;

		@media (max-width: $b3){
			min-height: 0px;
			padding-bottom: 50px;
		}
	}

	@media (max-width: $b3){
		min-height: 0px;
		padding-bottom: 50px;
	}

	.side-leaf,
	.side-house{
		position: absolute;
	}

	.side-leaf{
		top: 48.5%;
		left: 0.8%;

		@media (max-width: 1600px){
			left: -9.2%;
			width: 27.431%;
		}
	}

	.row{
		justify-content: center;
	}

	.profile-image{
		border-radius: 40px;
		width: 585.5px;
		margin: 0 auto 1em;
	}

	.side-house{
		top: 11.4%;
		right: -21.1%;

		@media (max-width: 1600px){
			right: -35.3%;
			width: 52.846%;
		}
	}

	.col-12{
		position: relative;
		z-index: 2;
	}
	
	.text{
		text-align: center;

		@media (min-width: $b3 + 1){
			flex: 0 0 65%;
			max-width: 65%;
		}

		.text-con{
			margin: 0 auto;
			width: 800px;
			position: relative;

			.small-text{
				position: absolute;
				left: -3px;
				bottom: -29px;
			}
		}
	}

	.btn-con{
		justify-content: center;
		margin-top: -.2em;
	}

	.learn-more{
		display: inline-block;
		margin-top: 1.7em;

		svg{
			position: absolute;
			top: 101%;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.hero-article-section{
	padding-top: 35px;
	padding-bottom: 30px;
	position: relative;

	.side-leaf,
	.side-house{
		position: absolute;
	}

	.side-leaf{
		top: 48.5%;
		left: 0.8%;

		@media (max-width: 1600px){
			left: -26.4%;
			width: 35.431%;
			top: 67.5%;
		}
	}

	.row{
		justify-content: center;
		position: relative;
	}

	.side-house{
		top: 11.4%;
		right: -21.1%;

		@media (max-width: 1600px){
			right: -61.7%;
			width: 69.446%;
			top: -13.6%;
		}
	}

	.col-12{
		position: relative;
		z-index: 2;
	}
	
	.text{
		text-align: center;

		@media (min-width: $b3 + 1){
			flex: 0 0 65%;
			max-width: 65%;
		}

		.text-con{
			margin: 0 auto;
			width: 800px;
			position: relative;

			.small-text{
				position: absolute;
				left: -3px;
				bottom: -29px;
			}
		}
	}

	.info{
		background-color: white;
		padding: 12px 30px 12px 39px;
		display: inline-flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 auto 1em;
		border-radius: 26px;

		.cats{
			display: flex;	
			@include font-size(18);
			font-weight: 700;
			color: $pink;
			font-family: $alt-font;
	
			span{
	
				&:after{
					content: ',';
					margin-right: .2em;
				}
	
		
				&:last-of-type{
	
					&:after{
						display: none;
					}
				}
			}
		}

		.spacer{
			@include font-size(18);
			font-weight: 600;
			opacity: .25;
			margin: 0 .4em;
		}

		.date{
			@include font-size(18);
			font-weight: 600;
		}
	}

	
}

.text-with-image-alt-section,
.text-with-image-section{
	padding-top: 66px;
	padding-bottom: 181px;

	@media (max-width: $b3){
		padding-top: 51px;
		padding-bottom: 101px;
	}

	.container{
		max-width: 987px;
	}

	.text{

		@media (max-width: $b3){
			order: 2;
		}

		.btn-con{
			margin-top: 2.7em;

			@media (max-width: $b3){
				margin-top: 1.9em;
				
				.btn{
					width: 100%;
				}
			}
		}
	}

	.image{

		@media (max-width: $b3){
			margin-bottom: 1.6em;
		}

		img{
			border-radius: 40px;
		}
	}
}


.therapy-programmes-section + .text-with-image-section{
	position: relative;
	padding-top: 50px;
	padding-bottom: 0;

	&:before{
		content: '';
		background-image: url(../images/bg-shape-top.svg);
		background-repeat: no-repeat;
		width: 100%;
		padding-top: 19%;
		display: block;
		background-size: 100% auto;
		margin-bottom: -1px;
	}

	.container{
		position: relative;
		z-index: 1;
		padding-top: 25px;
		padding-bottom: 52px;

		&:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			width: 100vw;
			max-width: 100vw;
			transform: translateX(-50%);
			background-color: $green;
			z-index: -1;
		}

		*{
			color: white;
		}

		.btn{
			background-color: $green-light;
			border-color: $green-light;
			color: $green!important;
			box-shadow: 5px 7px 0 $white;

			&.big{
				box-shadow: 13px 9px 0 $white;

				&:hover,
				&:focus-visible{
					box-shadow: 13px 9px 0 $green-light;
				}
			}

			&:hover,
			&:focus-visible{
				background-color: $white;
				border-color: $white;
				color: $green!important;
				box-shadow: 5px 7px 0 $green-light;
			}
		}
	}
}

.text-with-image-section{
	
	.text{

		@media (min-width: $b2 + 1){
			padding-right: 65px;
		}
	}
}

.text-with-image-alt-section{
	background-color: $green;
	padding-top: 121px;
	padding-bottom: 205px;

	@media (max-width: $b3){
		padding-bottom: 85px;
	}

	*{
		color: white;
	}

	.text{

		@media (min-width: $b2 + 1){
			padding-left: 47px;
			padding-top: .5em;
			padding-right: 40px;
		}

		h1,
		h2,
		h3{
			
			@media (min-width: $base-width + 1){
				width: 130%;
				max-width: 130%;
			}
		}

		h3{
			margin-bottom: .5em;
		}

		.btn-con{
			margin-top: 2em;
		}
	}

	.image{
		position: relative;

		img{
			position: relative;
			z-index: 2;
		}

		.side-leaf{
			border-radius: 0;
			position: absolute;
			z-index: 1;
			width: 78.8%;
			left: -31.4%;
			margin: 0;
			bottom: -47px;
			z-index: 1;
			border-radius: 0;
		}
	}
}

.hero-just-text-section + .therapy-programmes-section{

	&:before{
		display: none;
	}

	.container{

		&:after{
			display: none;
		}
	}

	*{
		color: $green;
	}

	.side-leaf{
		display: none;
	}

	.title-con{

		.btn{
			display: none;
		}
	}

	.th-item{

		.text-bottom{

			*{
				color: $green;
			}

			p{
				color: $colour;
			}
		}
	}
}

.therapy-programmes-section{
	position: relative;

	&:before,
	.vid-top-bg {
		content: '';
		background-image: url(../images/bg-shape-top.svg);
		background-repeat: no-repeat;
		width: 100%;
		padding-top: 19%;
		display: block;
		background-size: 100% auto;
		margin-bottom: -1px;
	}

	.vid-top-bg {
		padding-top: 0 !important;

		svg {
			width: 100%;
		}
	}

	.container{
		position: relative;
		z-index: 1;
		padding-top: 52px;
		padding-bottom: 52px;

		@media (max-width: $b3){
			padding-bottom: 90px;
		}

		.side-leaf,
		.side-house{
			position: absolute;
		}

		.side-leaf{
			top: -68px;
			right: -13.1%;
			
			@media (max-width: $b3){
				display: none;
			}
		}

		&:after, 
		.vid-main-bg {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			width: 100vw;
			max-width: 100vw;
			transform: translateX(-50%);
			background-color: $green;
			z-index: -1;
		}
	}
	
	* {
		color: white;
	}

	&.text-white * {
		color: white
	}

	&.text-black * {
		color: black
	}

	.title-con{
		position: relative;
		margin-bottom: 5.01em;

		@media (max-width: $b3){
			margin-bottom: 2.4em;
		}

		&.video-title-con {
			margin-bottom: 2.4em;
		}

		h2{
			padding-right: 220px;
			margin-bottom: 0;

			@media (max-width: $b3){
				padding-right: 0px;
			}
		}

		.btn{
			position: absolute;
			right: 15.5px;
			top: 75%;
			transform: translateY(-50%);
			min-width: 200px;

			@media (max-width: $b3){
				display: none;
			}
		}
	}

	.therapy-slide-con{

		.swiper-pag-wrapper {
			
			.swiper-pagination {
				position: static;
				height: 30px;
				align-items: center;
				transform: none;
				margin-top: 30px;
			}

			.swiper-pagination-bullet {
				width: 10px;
				height: 10px;
				background: $green-light;
				opacity: 0.4;

				&-active {
					background: $green-light;
					opacity: 1;
				}
			}
		}

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.swiper-button-next,
		.swiper-button-prev {
			top: auto;
			bottom: 0;
			
			@media (min-width: 1265px){
				opacity: 0;
				visibility: visible;
			}
		}

		.swiper-button-next {
			right: 0;
		}

		.swiper-button-prev {
			left: 0;
		}

		@media (min-width: 1265px){
			.swiper-pag-wrapper {
				display: none;
			}
		}
	}


	&.vid {
		.container::after,
		&::before {
			display: none !important;
		}
	}

	&.last-section:last-of-type {
		.bottom-image {
			margin-top: 0 !important;
		}
	}
}

.therapy-programmes-section + .therapy-programmes-section {
	.vid-top-bg {
		display: none;
	}

	.container {
		@media (min-width: $b3+1px){
			padding-top: 180px;
		}

		.side-leaf {
			@media (min-width: $b3+1px){
				top: 60px
			}
		}
	}
}

.th-item{
	
	.inner{
		width: 100%;
		position: relative;
		border-radius: 39px 39px 0 0;
	}

	a{
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 39px 39px 0 0;

		&:hover,
		&:focus-visible{
			
			~ .img-con{

				&:after{
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	.img-con{
		width: 100%;
		padding-top: 76.6%;
		border-radius: 39px;
		margin-bottom: 1.1em;
		position: relative;

		&:after{
			content: 'Explore More';
			@include font-size(42);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			border-radius: 39px;
			background-color: $green-light;
			color: $green;
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: $alt-font;
			font-weight: 700;
			transition: $time;
			opacity: 0;
			visibility: hidden;
		}
	}

	h4{
		margin-bottom: .28em;
	}

	p{
		@include font-size(18);
		@include line-height(18,28);
		margin-bottom: 1.3em;
	}

}

.video-slide {
	.th-item {
		.img-con {
			&:after{
				content: 'Watch Now';
			}
		}
	}
}

.text-with-image-section + .quotes-section{
	padding-top: 168px;
}

.quote-section,
.quotes-section{
	position: relative;
	padding-top: 130px;
	padding-bottom: 50px;

	@media (max-width: $b3){
		padding-top: 46px;
	}

	&:before{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		background-color: $green;
		height: 455px;

		@media (max-width: $b3){
			height: 650px;
		}
	}

	.container{
		max-width: 1020px;
	}

	.row{
		align-items: center;
	}

	.col-12{

		&:nth-of-type(1){
			transform: rotate(6deg);

			&::after{
				content: '';
				position: absolute;
				bottom: 37px;
				left: -17px;
				width: 113px;
				height: 113px;
				border-radius: 20px;
				box-shadow: -6px 6px 0 black;
				background-color: $yellow;
				transform: rotate(24deg);

				@media (max-width: $b3){
					width: 84.26px;
					height: 84.26px;
					bottom: 43px;
					left: 53px;
				}
			}
		}

		&:nth-of-type(2){
			transform: rotate(-6deg);

			@media (max-width: $b3){
				
				blockquote{
					margin-bottom: 0;
				}
			}

			&::after{
				content: '';
				position: absolute;
				top: -48px;
				right: 25px;
				width: 89.45px;
				height: 89.45px;
				border-radius: 20px;
				box-shadow: -6px -6px 0 black;
				background-color: $pink;
				transform: rotate(-24deg);

				@media (max-width: $b3){
					width: 89.45px;
					height: 89.45px;
					top: -42px;
					right: 27px;
				}
			}

			@media (min-width: $b2 + 1){
				
				.inner{
					transform: translateX(85px);
				}
			}
		}

		.inner{
			background-color: $orange;
			border-radius: 27px;
		}

		blockquote{
			color: white;
		}
	}
}


.quotes-section{

	@media (max-width: $b3){
		
		.quote-con{

			.inner{
				max-width: calc(100% - 28px);
				margin: 0 auto;
			}
		}
	}

	.row{

		&.waypoint{

			.col-12:nth-of-type(1){

				&::after{
					opacity: 0;
					visibility: hidden;
					transform: translateY(50px) rotate(0deg);
					transition: $time;
				}
			}

			.col-12:nth-of-type(2){

				&::after{
					opacity: 0;
					visibility: hidden;
					transform: translateY(50px) rotate(0deg);
					transition: $time;
				}
			}

			&.animate{

				.col-12:nth-of-type(1){

					&::after{
						opacity: 1;
						visibility: visible;
						transform: translateY(0px) rotate(24deg);
						transition-delay: .5s;
					}
				}

				.col-12:nth-of-type(2){

					&::after{
						opacity: 1;
						visibility: visible;
						transform: translateY(0px) rotate(-24deg);
						transition-delay: .5s;
					}
				}
			}
		}
	}
}

.quote-section{
	padding-top: 136px;

	@media (max-width: $b3){
		padding-top: 94px;
	}

	.container{
		max-width: 760px;
	}

	&:before{
		background-color: $green-light;
		height: 291px;
	}

	.row{

		&.waypoint{

			.col-12:nth-of-type(1){

				&::after{
					opacity: 0;
					visibility: hidden;
					transform: translateY(50px) rotate(0deg);
					transition: $time;
				}
			}

			&.animate{

				.col-12:nth-of-type(1){

					&::after{
						opacity: 1;
						visibility: visible;
						transform: translateY(0px) rotate(-24deg);
						transition-delay: .5s;
					}
				}
			}
		}
	}

	.col-12{

		.inner{
			background-color: $green;
		}
		
		blockquote{
			text-align: center;
			padding: 99px 79px 67px;

			@media (max-width: $b2){
				padding: 66px 72px 45px;
				margin-bottom: 0;
			}

			&::before{
				top: .1em;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		&:nth-of-type(1){
			transform: rotate(-6deg);

			&::after{
				content: '';
				position: absolute;
				top: -79px;
				left: 18%;
				width: 113px;
				height: 113px;
				border-radius: 20px;
				box-shadow: 6px 6px 0 $pink;
				background-color: $yellow;
				transform: rotate(-24deg);
			}
		}
	}
}

.text-with-video-section{
	text-align: center;
	padding-top: 40px;
	padding-bottom: 40px;

	@media (max-width: $b3){
		
		h3{
			margin-bottom: .65em;
		}
	}

	.container{
		max-width: 862px;
	}

	.btn-con{
		justify-content: center;
		margin-top: 2.3em;

		@media (max-width: $b3){
			margin-top: 1.9em;
		}
	}

	.text{

		@media (min-width: $b2 + 1){
			padding-right: 45px;
			padding-left: 45px;
		}
	}

	.video{
		margin-top: 7em;
		position: relative;

		@media (max-width: $b3){
			margin-top: 3.9em;
		}

		.leaf-left,
		.leaf-right{
			position: absolute;
		}


		.leaf-left{
			top: -28.4%;
			left: -22.7%;
			width: 44.7%;

			@media (max-width: $b3){
				top: -22.4%;
			}
		}

		.leaf-right{
			top: 58.5%;
			right: -19.8%;
			width: 43.5%;

			@media (max-width: $b3){
				top: 66.5%;
				right: -16.8%;
			}
		}

		a{
			border-radius: 40px;
			overflow: hidden;
			display: inline-block;
			position: relative;

			@media (max-width: $b3){
				border-radius: 15px;
			}

			&:hover,
			&:focus-visible{
				
				svg{

					*{
						fill: $green-light;
					}
				}
			}

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(black, .35);
			}

			svg{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;

				@media (max-width: $b3){
					width: 100px;
				}

				*{
					transition: $time;
					fill: white;
				}
			}

		}
	}
}

.our-supporters-section{
	padding-top: 50px;
	padding-bottom: 305px;

	@media (max-width: $b3){
		padding-top: 30px;
		padding-bottom: 295px;

		.bottom-image.bottom-image.bottom-image.bottom-image.bottom-image{
			margin-top: 210px;
		}
	}

	&.last-section{

		.container{
			margin-bottom: 5em;
		}
	}

	.row{
		position: relative;
	}

	.small-text.small-text.small-text{
		position: absolute;
		right: 191px;
		bottom: -181px;
		@include font-size(89);
		transform: rotate(6deg);
		color: black;
		background-image: url(../images/line2.svg);
		background-repeat: no-repeat;
		background-position: 0% 74%;
		min-width: 361.4658px;

		@media (max-width: $b3){
			right: 50%;
			transform: translate(50%) rotate(6deg);
		}

		@media (max-width: 375px){
			@include font-size(69);
			min-width: 280px;
			background-size: 280px auto;
			text-align: center;
		}
	}

	.title-con{
		text-align: center;
		margin-bottom: 1em;

		@media (max-width: $b3){
			margin-bottom: 1.8em;
		}

		*{
			color: $green;
		}
	}

	.logos{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		@media (max-width: $b3){
			justify-content: center;
		}

		.logo{
			max-width: 20%;
			display: flex;
			justify-content: center;
			align-items: center;

			@media (max-width: $b3){
				width: 100%;
				max-width: 100%;
				margin-bottom: 4.1em;

				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
	}
}

.hero-with-image-section + .text-with-images-section{
	padding-top: 79px;
	padding-bottom: 184px;

	h3{
		margin-bottom: .75em;
	}

	.image{

		@media (min-width: $b3 + 1){
			margin-top: 2.2em;
		}
	}
}

.text-with-images-section{
	background-color: $green-light;
	padding-top: 227px;
	padding-bottom: 75px;

	@media (max-width: $b3){
		padding-top: 117px;
		padding-bottom: 50px;
	}

	.text{

		*{
			position: relative;
			z-index: 2;
		}
	}

	.small-text.small-text.small-text{
		color: $black;
		background-image: url(../images/line-pink.svg);
		z-index: 1;
		position: absolute;
		top: -31px;
		left: 9px;
		min-width: 186.9396px;

		@media (max-width: $b3){
			top: -32px;
			left: 28px;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li{
		color: $green;
	}

	h3{
		margin-bottom: .62em;

		@media (max-width: $b3){
			margin-bottom: .55em;
		}
	}
	
	.btn-con{
		margin-top: 3.6em;

		@media (max-width: $b3){
			margin-top: 2em;
		}

		.btn{
			min-width: 200px;

			@media (max-width: $b3){
				width: 100%;
			}
		}
	}

	.image{

		.img-con{

			img{
				border-radius: 25px;
			}

			.caption{
				font-weight: 500;
				@include font-size(20);
				color: $green;
				font-family: $alt-font;
				margin-bottom: .8em;

				@media (max-width: $b3){
					@include font-size(24);
					margin-bottom: 1.1em;
				}
			}

			&.img-con1{
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 1.3em;
				position: relative;
				padding-top: 3em;

				@media (max-width: $b3){
					padding-top: 4.4em;
					margin-bottom: 2.7em;
				}

				.side-left{
					position: absolute;
					width: 54%;
					left: 9.2%;
					margin: 0;
					top: 0;
					z-index: 1;
					border-radius: 0;

					@media (max-width: $b3){
						width: 113%;
						max-width: 113%;
						left: -90.8%;
						top: 1%;
					}
				}

				.caption,
				img{
					width: 46%;
					margin-left: 54%;
					text-align: right;
					position: relative;
					z-index: 2;

					@media (max-width: $b3){
						width: 100%;
						margin-left: 0;
					}
				}

				.caption{
					text-shadow: 0 0 5px $green-light;
				}
			}

			&.img-con2{
				position: relative;

				.side-right{
					position: absolute;
					width: 64%;
					right: -22.4%;
					margin: 0;
					//bottom: -24px;
					top: 42%;
					z-index: 1;
					border-radius: 0;
				}

				.caption,
				img{
					width: 65.5%;
					margin-left: 21%;
					position: relative;
					z-index: 2;

					@media (max-width: $b3){
						width: 100%;
						margin-left: 0;
					}
				}

				.caption{
					margin-top: .8em;
					margin-bottom: 0;
					text-shadow: 0 0 5px $green-light;

					@media (max-width: $b3){
						margin-top: 1.2em;
					}
				}
				
			}
		}

		img:nth-of-type(1){

		}
	}
}

.quotes-section + .articles-resources-section{

	.title-con{

		h3{
			padding-right: 0;
		}

		.btn{
			display: none;
		}
	}
}

.blog-filters {
	margin-bottom: 30px;

	@include bp-lg {
		margin-bottom: 0;
	}

	.row {
		align-items: center;
	}

	select {
		margin-bottom: 24px;
		height: 63px;

		@include bp-lg {
			margin-bottom: 50px;
		}
	}

	.btn {
		width: 100%; 
		margin-bottom: 24px;

		@include bp-lg {
			margin-bottom: 50px;
		}
	}
}

.articles-resources-section{
	position: relative;
	padding-top: 52px;
	padding-bottom: 128px;

	@media (max-width: $b3){
		padding-top: 37px;
		padding-bottom: 138px;
	}

	.title-con{
		position: relative;
		margin-bottom: 3.7em;

		@media (max-width: $b3){
			margin-bottom: 3.2em;
			position: static;
		}

		h3{
			padding-right: 380px;
			margin-bottom: 0;

			@media (max-width: $b3){
				padding-right: 0px;
				text-align: center;
			}
		}

		.btn{
			position: absolute;
			right: 15.5px;
			top: 42%;
			transform: translateY(-50%);
			min-width: 375px;

			@media (max-width: $b3){
				top: auto;
				bottom: 37px;
				right: 24px;
				width: calc(100% - 24px - 24px);
				min-width: 0;
			}
		}
	}

	&.under{

	}

	.th-item{
		margin-bottom: 31px;

		h4{
			color: $green;
		}
	}
}

.a-item{
	margin-bottom: 1.6em;

	@media (max-width: $b3){
		margin-bottom: 2.5em;
	}

	&.featured{
		
		@media (min-width: $b3 + 1){
			
			@media (max-width: $b2){
				
				&.col-lg-8{
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}

		&.col-lg-6.col-lg-6.col-lg-6{

			.inner{

				.text-bottom{

					&::before{
						top: -35px;
						background-image: url(../images/af-top-pink.svg);
					}

					&::after{
						background-color: $pink;	
					}

					h6{
						@include font-size(32);
						width: 100%;
						padding-top: .85em;
						margin-bottom: 0.5em;
					}

					p{
						color: white;
						font-weight: 600;
					}

				}
			}
		}

		@media (max-width: $b3), (min-width: $b2  + 1){
			
			&.col-lg-4{

				.inner{

					a:not(.btn){
		
						&:hover,
						&:focus-visible{
							
							~ .text-bottom{
		
								.btn{
									background-color: $white;
									border-color: $white;
									color: $green!important;
									box-shadow: 5px 7px 0 $green-light;
								}
							}
						}
					}
			
					.img-con{
						border-radius: 40px 40px 0 0;
					}
		
					.text-bottom{
						border-radius: 0 0 40px 40px;
						padding: 0 39px 45px;
						margin-top: -42px;
						position: relative;
						z-index: 1;
		
						&:before{
							content: '';
							position: absolute;
							top: -46px;
							left: 0;
							right: 0;
							height: 79.6653px;
							background-image: url(../images/af-top.svg);
							background-repeat: no-repeat;
						}
		
						&:after{
							content: '';
							position: absolute;
							top: 32.665px;
							left: 0;
							right: 0;
							background-color: $green;
							height: calc(100% - 32.665px);
							z-index: -1;
							border-radius: 0 0 40px 40px;
						}
		
						.date{
							color: rgba(white, .5);
							position: relative;
							z-index: 1;
						}
		
						h6{
							color: white;
							position: relative;
							z-index: 1;
						}
		
						.btn{
							background-color: $green-light;
							border-color: $green-light;
							color: $green!important;
							box-shadow: 5px 7px 0 $white;
		
							@media (max-width: $b3){
								width: 100%;
							}
		
							&.big{
								box-shadow: 13px 9px 0 $white;
		
								&:hover,
								&:focus-visible{
									box-shadow: 13px 9px 0 $white;
								}
							}
		
							&:hover,
							&:focus-visible{
								background-color: $white;
								border-color: $white;
								color: $green!important;
								box-shadow: 5px 7px 0 $green-light;
							}
						}
					}
				}
			}
		}

		&.col-lg-8,
		&.col-lg-6{

			.inner{

				a:not(.btn){
	
					&:hover,
					&:focus-visible{
						
						~ .text-bottom{
	
							.btn{
								background-color: $white;
								border-color: $white;
								color: $green!important;
								box-shadow: 5px 7px 0 $green-light;
							}
						}
					}
				}

				.img-con{
					border-radius: 40px 40px 0 0;
				}
	
				.text-bottom{
					border-radius: 0 0 40px 40px;
					padding: 0 39px 45px;
					margin-top: -42px;
					position: relative;
					z-index: 1;
	
					&:before{
						content: '';
						position: absolute;
						top: -46px;
						left: 0;
						right: 0;
						height: 79.6653px;
						background-image: url(../images/af-top.svg);
						background-repeat: no-repeat;
					}
	
					&:after{
						content: '';
						position: absolute;
						top: 32.665px;
						left: 0;
						right: 0;
						background-color: $green;
						height: calc(100% - 32.665px);
						z-index: -1;
						border-radius: 0 0 40px 40px;
					}
	
					.date{
						color: rgba(white, .5);
						position: relative;
						z-index: 1;
					}
	
					h6{
						color: white;
						position: relative;
						z-index: 1;
					}
	
					.btn{
						background-color: $green-light;
						border-color: $green-light;
						color: $green!important;
						box-shadow: 5px 7px 0 $white;
	
						@media (max-width: $b3){
							width: 100%;
						}
	
						&.big{
							box-shadow: 13px 9px 0 $white;
	
							&:hover,
							&:focus-visible{
								box-shadow: 13px 9px 0 $white;
							}
						}
	
						&:hover,
						&:focus-visible{
							background-color: $white;
							border-color: $white;
							color: $green!important;
							box-shadow: 5px 7px 0 $green-light;
						}
					}
				}
			}
		}
	
	}

	.inner{
		width: 100%;
		position: relative;

		a:not(.btn){
			position: absolute;
			z-index: 3;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 40px;

			&:hover,
			&:focus-visible{
				
				~ .text-bottom{

					.btn{
						background-color: $green;
						border-color: $green;
						color: $green-light!important;
						box-shadow: 5px 7px 0 $green-light;
					}
				}
			}
		}

		.img-con{
			height: 313px;
			border-radius: 40px;
			width: 100%;
			background-position: 50% 0%;
			background-color: $pink;

			div{
				display: flex;
				position: absolute;
				top: 37px;
				background-color: rgba(255, 255, 255, 0.8);
				padding: 12px 30px 12px 39px;
				border-radius: 0 26px 26px 0;
				@include font-size(18);
				font-weight: 700;
				color: $pink;
				font-family: $alt-font;
				flex-wrap: wrap;
				max-width: calc(100% - 15px);

				span{

					&:after{
						content: ',';
						margin-right: .2em;
					}

			
					&:last-of-type{

						&:after{
							display: none;
						}
					}
				}
			}
		}

		.text-bottom{
			padding: 19px 0px 10px;

			.date{
				@include font-size(18);
				color: rgba(black, .25);
				font-weight: 600;
			}

			h6{
				@include font-size(28);
				@include line-height(28,33);
				color: $green;
				font-weight: 700;
				margin-bottom: 0.9em;
				width: 301.992px;
			}

			.btn{
				min-width: 190px;

				&:hover,
				&:focus-visible{
					background-color: $green;
					border-color: $green;
					color: $green-light!important;
					box-shadow: 5px 7px 0 $green-light;
				}

				@media (max-width: $b3){
					width: 100%;
				}
			}
		}

	}
}

.sbi_lightbox,
.ctf_lightbox{
	max-width: none;

	*{
		max-width: none;
	}
}

.follow-us-section{
	background-color: $pink;
	padding-top: 165px;
	padding-bottom: 165px;

	@media (max-width: $b3){
		padding-top: 107px;
		padding-bottom: 165px;
	}

	*{
		color: white;
	}

	.title-con{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 3.25em;

		@media (max-width: $b3){
			flex-direction: column;
			align-items: center;
			margin-bottom: 1.95em;
		}

		h4{
			margin-bottom: 0;

			@media (max-width: $b3){
				order: 2;
			}
		}

		a{
			@include font-size(42);

			@media (max-width: $b3){
				order: 1;
				@include font-size(62);
				margin-bottom: .19em;
			}

			&:hover,
			&:focus-visible{
				color: $green;

				*{
					color: $green;
				}
			}
		}
	}

	.col-md-6{
		margin-bottom: 6.6em;
		
		@media (max-width: $b3){
			
			&:nth-of-type(1){
				margin-bottom: 0;
			}

			&:nth-of-type(2){
				margin-bottom: 1.7em;
			}
		}
		
		#sbi_images{
			padding: 0!important;
			float: none!important;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			justify-content: space-between;

			.sbi_item{
				padding-top: 0;
				padding-bottom: 0;
				margin-bottom: 28px!important;

				&:nth-of-type(1),
				&:nth-of-type(4){
					width: calc(40% - 14px)!important;

					@media (max-width: 428px){
						width: 100%!important;
					}
				}

				&:nth-of-type(4){
					align-self: flex-start;
				}

				&:nth-of-type(2),
				&:nth-of-type(3){
					width: calc(60% - 14px)!important;

					@media (max-width: 428px){
						width: 100%!important;
					}
				}

				.sbi_photo_wrap{
					margin-bottom: 28px;
					border-radius: 25px;

					@media (max-width: 428px){
						margin-bottom: 44px;
					}

					.sbi_photo{
						height: auto!important;
						padding-top: 100%!important;
						border-radius: 25px;
					}
				}

				.sbi_link_area{
					border-radius: 25px;
				}

				.sbi_info{
					display: none;
				}
			}
		}

		.ctf-item.ctf-item.ctf-item{
			display: flex;
			flex-direction: column;
			padding: 0;
			border: none!important;
			border-bottom: 1px solid white!important;
			@include font-size(18);
			font-weight: 600;
			padding: 9px 0 28px !important;
			margin-bottom: 0.5em;

			&:last-of-type{
				border: none!important;
			}

			p{
				@include font-size(18);
				@include line-height(18,28);
				font-weight: 600;
			}

			.ctf-tweet-text{

				a{
					display: inline;
				}
			}

			.ctf-author-box{
				padding-left: 0!important;
				text-align: right;
				order: 3;
				font-style: italic;
				text-decoration: underline;
				line-height: 1;
				margin-top: -0.4em;

				*,
				a{
					@include font-size(18);
					text-decoration: underline;
					line-height: 1;
				}
			}
		}
	}

	@media (min-width: $b2 + 1){
		
		.col-md-6{

			&:nth-of-type(2){
				padding-left: 137px;
			}
		}
		
	}

	.small-con{
		margin-bottom: 2.3em;
	}

	.small-text.small-text.small-text{
		color: $yellow;
		background-image: url(../images/line-follow.svg);
		width: 361.4519px;
		margin: 0 auto;
		width: auto;
		text-align: center;
		@include font-size(89);
		background-position: 51.7% 76%;
	}

	.col-lg-3{
		text-align: center;

		@media (max-width: $b3){
			margin-bottom: 1.62em;
		}

		&.instagram{

			a span{
				transform: rotate(-6deg);
			}

			a{

				&:hover,
				&:focus-visible{
					
					span{
						transform: rotate(6deg);
					}
				}
			}
		}

		&.facebook{

			a{

				&:hover,
				&:focus-visible{
					
					span{
						transform: rotate(-12deg);
					}
				}
			}
		}

		&.twitter{

			a span{
				transform: rotate(6deg);
			}

			a{

				&:hover,
				&:focus-visible{
					
					span{
						transform: rotate(-6deg);
					}
				}
			}
		}

		&.linkedin{

			a{

				&:hover,
				&:focus-visible{
					
					span{
						transform: rotate(12deg);
					}
				}
			}
		}


		a{
			text-align: center;
			display: inline-block;

			&:hover,
			&:focus-visible{

				span{
					background-color: $green;

					i{
						color: $green-light;
					}
				}
			}

			span{
				display: inline-flex;
				width: 72px;
				height: 72px;
				align-items: center;
				justify-content: center;
				border-radius: 15px;
				background-color: $green-light;
				@include font-size(42);
				color: $green;
				box-shadow: 5px 5px 0 white;

				*{
					color: $green;
				}
			}

			h6{
				@include font-size(24);
				@include line-height(24,28);
				font-weight: 700;
				margin-top: .85em;
			}
		}
	}
}

.hero-with-image-section{
	padding-top: 56px;
	padding-bottom: 84px;

	.single-post &{

		.image{

			@media (min-width: $b3 + 1){
				padding-left: 110px;
			}
		}
	}

	&.text-with-images-next{
		position: relative;
		padding-bottom: 0;

		.bottom-shape{
			width: 100vw;
			margin-top: -13.7em;
			margin-bottom: -1px;

			@media (max-width: $b3){
				margin-top: -5em;
			}
		}
	}

	.text{
		position: relative;
		padding-top: 1.95em;
		align-self: flex-start;
		margin-bottom: 228px;

		@media (max-width: $b3){
			margin-bottom: 0px;
		}

		&.waypoint{

			&::after{
				opacity: 0;
				visibility: hidden;
				transform: translateX(-50px) rotate(0deg);
				transition: $time;
			}
			
			&.animate{

				&::after{
					opacity: 1;
					visibility: visible;
					transform: translateY(0px) rotate(30deg);
					transition-delay: .5s;
				}
			}
		}

		&:after{
			content: '';
			position: absolute;
			bottom: -214px;
			left: -84px;
			width: 106.18px;
			height: 106.18px;
			border-radius: 21px;
			box-shadow: -11px 8px 0 $orange;
			background-color: $green;
			transform: rotate(15deg);
		}

		.small-text.small-text.small-text{
			top: -25px;
			position: absolute;
			text-align: center;
		}

		h1{
			margin-bottom: .33em;
		}

		li,
		p{
			@include font-size(24);
			@include line-height(24,28);
			font-family: $alt-font;
			width: 510px;

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.learn-more{
			@include font-size(24);
			@include line-height(24,28);
			display: flex;
			align-items: center;
			font-family: $alt-font;
			font-weight: 700;
			color: $green;
			margin-top: 1.8em;

			&:hover,
			&:focus-visible{
				color: $pink;

				svg{

					*{
						fill: $pink;
					}
				}
			}

			svg{
				margin-left: .4em;
			}
		}
	}

	.image{

		@media (max-width: $b3){
			flex: 0 0 428px;
			margin-left: auto;
		}

		@media (min-width: $b3 + 1){
			padding-left: 45px;
		}

		&.waypoint{

			.img-con{

				.after{
					opacity: 0;
					visibility: hidden;
					transform: translateX(50px) rotate(0deg);
					transition: $time;
				}
			}

			&.animate{

				.img-con{

					.after{
						opacity: 1;
						visibility: visible;
						transform: translateY(0px) rotate(-30deg);
						transition-delay: 1s;
					}
				}
			}
		}

		.img-con{
			width: 160.8%;
			max-width: 160.8%;
			position: relative;

			@media (max-width: $b3){
				width: 140.8%;
				max-width: 140.8%;
			}

			.after{
				content: '';
				position: absolute;
				top: 4.8%;
				left: 8.8%;
				transform: rotate(-30deg);
				width: 169.13px;
				height: 169.13px;

				@media (max-width: $b1){
					width: 103.13px;
					height: 103.13px;
				}

				.inner-after{
					width: 169.13px;
					height: 169.13px;
					border-radius: 20px;
					box-shadow: 12px 12px 0 $pink;
					background-color: $orange;
					
					@media (max-width: $b1){
						width: 103.13px;
						height: 103.13px;
					}
				}
			}

			img{
				width: 100%;
			}
		}
	}
}

.meet-our-therapists-section{
	position: relative;
	padding-top: 278px;
	padding-bottom: 140px;

	@media (max-width: $b3){
		padding-top: 75px;
		padding-bottom: 140px;
	}

	.title-con{
		position: relative;
		margin-bottom: 5em;
		z-index: 2;

		@media (max-width: $b3){
			position: static;
		}

		h2{
			padding-right: 280px;
			margin-bottom: 0;

			@media (max-width: $b3){
				padding-right: 0;
			}
		}

		.btn{
			position: absolute;
			right: 15.5px;
			top: 74%;
			transform: translateY(-50%);
			min-width: 269px;

			@media (max-width: $b3){
				position: static;
				transform: none;
				width: 100%;
				margin-top: 1.3em;
			}
		}

		.leaf-back{
			position: absolute;
			right: -13.1%;
			margin: 0;
			bottom: -168px;
			z-index: -1;

			@media (max-width: $b3){
				display: none;
			}
		}
	}

	.therapy-slide-con{
		position: relative;
		z-index: 2;

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.swiper-button-next,
		.swiper-button-prev{
			
			@media (min-width: $b3 + 1){
				opacity: 0;
				visibility: visible;
			}
		}
	}

	.th-item{

		h4{
			color: $green;
		}
	}
}

.stats-section{
	padding-top: 154px;
	text-align: center;
	background-color: $green;

	.container{
		position: relative;
		max-width: 1406px;

		&:after{
			content: '';
			position: absolute;
			top: -173px;
			right: 9.3%;
			width: 156.14px;
			height: 156.14px;
			border-radius: 20px;
			box-shadow: -10px 10px 0 $orange;
			background-color: $pink;
			transform: rotate(30deg);
		}
	}

	.btn-con{
		margin-top: 1.7em;

		.btn{
			min-width: 270px;

			&:hover,
			&:focus-visible{
				background-color: white;
				border-color: white;
				color: $green !important;
				box-shadow: 5px 7px 0 $green-light;
			}
		}
	}

	.text{
		margin-bottom: 7.1em;

		@media (min-width: $b3 + 1){
			flex: 0 0 57%;
			max-width: 57%;
		}
	}

	*{
		color: white;
	}

	.row{
		justify-content: center;
	}

	.btn-con{
		justify-content: center;
	}

	.stat{

		.stat-top{
			position: relative;
			margin-bottom: 1.6em;

			.count{
				@include font-size(81);
				@include line-height(81,95);
				font-family: $alt-font2;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				display: flex;
			}
		}

		img{
			margin: 0 auto;
		}

		.stat-bottom{
			width: 350px;
			margin: 0 auto;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6{
				color: $green-light;
			}

			h4{
				margin-bottom: .15em;
			}
		}
	}
}

.our-values-section{
	padding-top: 186px;
	padding-bottom: 40px;

	@media (max-width: $b3){
		padding-top: 85px;
	}

	h2{
		margin-bottom: .68em;
	}

	.text{
		margin-bottom: 5.4em;
	}

	.row{
		align-items: center;
		justify-content: center;
	}

	.value{
		margin-bottom: 2.7em;

		@media (min-width: $b2 + 1){
			padding-right: 36px;
			padding-left: 36px;
		}

		@media (max-width: 1024px){
			
			@media (min-width: $b3 + 1){
				flex: 0 0 50%;
				max-width: 50%;
			}
		}

		&:nth-of-type(3){

			&.waypoint{

				.inner{

					&:after{
						transform: rotate(0deg);
					}
				}
			}

			&.animate{
				
				.inner{

					&:after{
						opacity: 1;
						visibility: visible;
						transition: .5s;
						transition-delay: .5s;
						transform: rotate(-24deg);
					}
				}
			}
		}

		&:nth-of-type(4){

			&.animate{
				transition-delay: .1s;
			}
		}

		&:nth-of-type(5){

			&.waypoint{

				.inner{

					&:after{
						transform: rotate(0deg);
					}
				}
			}

			&.animate{
				transition-delay: .2s;

				.inner{

					&:after{
						opacity: 1;
						visibility: visible;
						transition: .5s;
						transition-delay: .7s;
						transform: rotate(-24deg);
					}
				}
			}
		}

		&:nth-of-type(6){

			&.waypoint{

				.inner{

					&:after{
						transform: rotate(0deg);
					}
				}
			}

			&.animate{
				transition-delay: .3s;

				.inner{

					&:after{
						opacity: 1;
						visibility: visible;
						transition: .5s;
						transition-delay: .8s;
						transform: rotate(-38deg);
					}
				}
			}
		}

		&:nth-of-type(7){

			&.animate{
				transition-delay: .4s;
			}
		}

		&:nth-of-type(8){

			&.animate{
				transition-delay: .5s;
			}
		}

		&:nth-of-type(9){

			&.animate{
				transition-delay: .6s;
			}
		}

		&:nth-of-type(10){

			&.animate{
				transition-delay: .7s;
			}
		}

		&:nth-of-type(2n + 1){

			.inner{
				transform: rotate(-6deg);
			}
		}

		&:nth-of-type(2n + 2){

			.inner{
				transform: rotate(6deg);
			}
		}

		&:nth-of-type(3),
		&:nth-of-type(5),
		&:nth-of-type(6){

			.inner{
				position: relative;

				&:after{
					content: '';
					position: absolute;
					width: 89.45px;
					height: 89.45px;
					border-radius: 20px;
					box-shadow: -5px -5px 0 $green;
					background-color: $pink;
					opacity: 0;
					visibility: hidden;
				}
			}
		}

		&:nth-of-type(3){

			.inner{

				&:after{
					transform: rotate(-24deg);
					top: -36px;
					right: -20px;
				}
			}
		}

		&:nth-of-type(5){

			.inner{

				&:after{
					transform: rotate(-24deg);
					bottom: -35px;
					right: -60px;
					box-shadow: 5px 5px 0 $pink;
					background-color: $orange;

				}
			}
		}

		&:nth-of-type(6){

			.inner{

				&:after{
					transform: rotate(-38deg);
					bottom: -51px;
					right: -9px;
					box-shadow: -5px -5px 0 $orange;
					background-color: $green;

				}
			}
		}

		.inner{
			background-color: $yellow;
			padding: 46px 43px 15px;
			border-radius: 27px;

			.small-text{
				transform: rotate(0deg);
				@include font-size(70);
				color: $pink;
				background-image: url(../images/line-follow.svg);
				background-repeat: no-repeat;
				min-width: 0;
				background-size: auto 8.14px;
				width: auto;
				display: inline-block;
				margin-bottom: 0.31em;

				@media (max-width: 414px){
					@include font-size(48);
				}
			}

			p{
				@include font-size(24);
				@include line-height(24,28);
				font-family: $alt-font;
			}
		}
	}
}

.page-template-template-creative-therapy-referral{
	
	.gform_confirmation_wrapper{
		background: #fff;
		padding: 40px;
		border-radius: 30px;

		@media (min-width: $b3 + 1){
			width: calc(100% - 300px);
			padding: 50px;
			border-radius: 40px;
		}
	}
}

.our-board-section{
	padding-top: 83px;
	padding-bottom: 59px;

	.container{
		max-width: 1203px;
		position: relative;

		.leaf-back{
			position: absolute;
			right: -7.9%;
			bottom: -72px;
			z-index: 1;
		}
	}

	.row{
		border-radius: 40px;
		background-color: white;
		padding: 107px 108px 17px;
		position: relative;
		z-index: 2;

		@media (max-width: 1240px){
			margin-right: 0px;
			margin-left: 0px;
		}

		@media (max-width: $b3){
			padding: 38px 11px 17px;
			margin-right: 0px;
			margin-left: 0px;
		}

		@media (max-width: 428px){
			padding: 38px 0 17px;
		}

	}

	.title-con{
		margin-bottom: 3.9em;

		@media (max-width: $b3){
			margin-bottom: 2.2em;
		}

		h3{
			margin-bottom: 0;
		}
	}

	dl.board{
		display: flex;
		flex-wrap: wrap;

		dt,
		dd{
			border-top: 1px solid rgba(black, .15);
			padding-top: 30px;
			padding-bottom: 4px;
		}

		dt{
			width: 38.6%;
			padding-top: 32px;

			@media (max-width: $b3){
				width: 100%;
			}

			h6{
				color: $green;
				font-weight: 700;
			}
		}

		dd{
			width: 61.4%;
			margin: 0;
			@include font-size(18);
			@include line-height(18,28);
			min-height: 229px;

			@media (max-width: $b3){
				width: 100%;
				border-top: none;
				padding-top: 0;
			}
		}

		p,
		li{
			@include font-size(18);
			@include line-height(18,28);
			font-family: $main-font;
		}
	}
}

.article-section{

	.container{
		max-width: 986px;
		position: relative;

		img{
			border-radius: 39px;
			margin-bottom: 2.9em;
		}
	}

	.text{

		h1,
		h2,
		h3,
		h4,
		h5,
		h6{
			color: $green;
			font-weight: 700;
		}
	
		h6{
			margin-bottom: .8em;
		}
	}

	.quote{

		blockquote{
			padding: 64px 64px 0px 95px;
			@include font-size(54);
			@include line-height(54,54);
			margin-bottom: .5em;
			color: $green;

			@media (max-width: $b2){
				padding: 64px 42px 0px 42px;
				@include font-size(42);
			}

			&:before{
				@include font-size(131);
				color: $green;
				top: .24em;
				left: .23em;

				@media (max-width: $b3){
					@include font-size(100);
					left: 0em;
				}
			}
		}

		.by{
			padding: 0px 64px 0px 135px;
			@include font-size(24);
			@include line-height(24,28);
			font-family: $alt-font;
			font-weight: 700;
			background-position: 96px 50%;

			@media (max-width: $b2){
				padding: 0px 42px 0px 80px;
				background-position: 42px 50%;
			}
		}
	}

	.share-con{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 3.9em;
		padding-bottom: 49px;
		background-image: url(../images/share-line.svg);
		background-repeat: no-repeat;
		background-size: 100% 4.589px;
		background-position: 50% 100%;
		margin-bottom: 1.75em;

		.share-side{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding-top: .35em;

			@media (max-width: $b3){
				width: 100%;
			}

			h6{
				font-weight: 700;
				margin-bottom: 0;
				margin-right: .92em;

				@media (max-width: $b2){
					@include font-size(24);
				}

				@media (max-width: $b3){
					width: 100%;
					text-align: center;
					margin-right: 0;
					margin-bottom: 1em;
				}
			}
		}

		.social-menu{

			@media (max-width: $b3){
				width: 100%;
				justify-content: center;
			}

			a{

				*{
					color: $green!important;
				}

				&:hover,
				&:focus-visible{
					
					*{
						color: $pink!important;
					}
				}
			}
		}

		.copy-side{
			position: relative;

			@media (max-width: $b3){
				margin-top: 1em;
				width: 100%;
				text-align: center;
			}

			#copyPlace,
			#link{
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 0;
				overflow: hidden;
				pointer-events: none;
				opacity: 0;
			}

			.btn{
				padding-right: 18px;
				padding-left: 18px;
			}

			a{
				padding-right: 21px;
				padding-left: 21px;
			}
		}
	}
}

.top-section{
	background-color: $green!important;
	display: block;
	padding: 10px 0;

	@media (max-width: $b2){
		margin-top: .75em;
		padding: 19px 0;
	}

	h4{
		margin-bottom: 0;

		@media (max-width: $b2){
			@include font-size(32);
		}
	}

	*{
		color: white;
	}

}

.form-section,
.step-form-section{
	overflow: hidden;

	.side-leaf,
	.side-house{
		position: absolute;
	}

	.side-leaf{
		top: 566px;
		left: 0.8%;

		@media (max-width: 1600px){
			left: -9.2%;
			width: 27.431%;
		}

		@media (max-width: $b3){
			top: 913px;
			left: -21.2%;
			width: 62.431%;
		}
	}

	.side-house{
		top: 338px;
		right: -21.1%;

		@media (max-width: 1600px){
			right: -35.3%;
			width: 52.846%;
		}

		@media (max-width: $b3){
			top: 630px;
			right: -85.8%;
			width: 117.246%;
			max-width: 117.246%;
		}
	}

	#gf_page_steps_1{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 26px 0;
		width: 100%;
		@include font-size(20);
		position: relative;
		z-index: 2;
		margin-bottom: 2.7em;

		@media (max-width: $b3){
			padding: 26px 0;
			min-width: 1221px;
			overflow: auto;
			margin-left: -24px;
			padding-left: 24px;
		}

		@media (max-width: 395px){
			min-width: 1075px;
		}

		&:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			width: 400vw;
			max-width: 400vw;
			width: 250%;
			max-width: 250%;
			background-color: #DFE9F4;
			z-index: -1;
			transform: translateX(-50%);
		}

		.gf_step_number{
			display: none;
		}

		.gf_step{
			background-image: url(../images/step-empty.svg);
			background-repeat: no-repeat;
			min-height: 53.18px;
			display: flex;
			align-items: center;
			font-weight: 700;
			font-family: $alt-font;
			padding-left: 68px;
			padding-right: 10px;
			@include font-size(20);
			flex: 1 1 auto;
			background-position: 0 50%;

			&:last-of-type{
				padding-right: 0;
			}

			&.gf_step_completed{
				background-image: url(../images/step-tick.svg);
			}
		}
	}

	.row{
		min-height: 850px;

		@media (max-width: 1100px){
			min-height: 730px;
		}

		@media (max-width: $b3){
			min-height: 1060px;
		}

		@media (max-width: 500px){
			min-height: 780px;
		}
	}

	&.login-form{

		.gform-body{
			background-color: transparent;
			border-radius: 0;
			padding: 0;
			margin-bottom: 0;
		}

		.form-con{
			background-color: white;
			border-radius: 40px;
			padding: 58px 69px 62px;
			margin-bottom: 180px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@media (max-width: $b3){
				padding: 28px 32px 62px;
				margin-bottom: 59px;
			}
		}

		.title-con{
			width: 100%;
			text-align: center;
			margin-bottom: 1.85em;

			@media (max-width: $b3){
				margin-bottom: 0.05em;
			}

			h3{
				color: $green;
				margin-bottom: .3em;
			}

			h6{
				font-weight: 700;
				margin-right: auto;
				margin-left: auto;
				width: 600px;
			}
		}

		.login-side,
		.register-side{
			padding-top: 1em;
			width: 50%;
			margin-bottom: 2.8em;

			@media (max-width: 1100px){
				width: 100%;

				.text-con{
					text-align: center;
				}

				label{
					//text-align: center;
				}
			}
		}

		.login-side{
			position: relative;

			.error_wid_login{
				border: none;
				padding: 0;
				color: $red;
				font-family: $main-font;
				margin-bottom: 1em;
				background-color: transparent;
				@include font-size(18);
				line-height: 1.55556em;

				.close_button_ap{
					display: none;
				}
			}

			&:after{
				content: '*Mandatory fields';
				@include font-size(18);
				font-weight: 600;
				position: absolute;
				bottom: -36px;
				left: 0;
				color: #7F7F7F;
			}

			@media (min-width: 1100px + 1){
				padding-right: 68px;
				border-right: 4px solid $blue-back;
			}

			.login-wrap{
				padding: 0;
				width: 100%;
				max-width: 100%;
				border: none;

				.log-form-group{
					margin-bottom: 0;
					position: relative;
				}


				input[type="submit"]{
					@extend %btn;
					height: auto;
					width: auto;
					background-image: url(../images/arrow-right-white.svg);
					background-repeat: no-repeat;
					background-position: right 40px top 50%;
					min-width: 0;
					padding-right: 74px;
					padding-left: 37px;

					@media (max-width: 1100px){
						width: 100%;
						background-position: right calc(50% - 40px) top 50%;
					}

					&:hover,
					&:focus-visible{
						background-image: url(../images/arrow-right-pink.svg);
					}
				}

				label{
					margin-bottom: 30px;

					&.lw-error{
						@include font-size(12);
						line-height: 1;
						position: absolute;
						bottom: 10px;
						font-weight: 700;
						color: $red;
						font-family: $main-font;
						margin-bottom: 0;

					}
				}

				input[type="text"], 
				input[type="password"]{
					display: block;
					padding: 16px 16px;
					@include font-size(18);
					font-weight: 400;
					color: $grey-form;
					font-family: $main-font;
					width: 100%;
					max-width: 100%;
					margin-bottom: $input-bottom-margin;
					border: 2px solid $blue-border;
					background-color: $blue-form;
					border-radius: 10px;
					line-height: 1.3em;
					-webkit-appearance: none;
					box-shadow: none;
					height: auto;

					&.lw-error{
						border-color: $red;
					}
				}
			}

			.login-remember{
				display: none;
			}

			.login-submit{
				padding-top: 6px;

				@media (max-width: 1100px){
					text-align: center;
				}

				#wp-submit{
					background-image: url(../images/arrow-right-white.svg);
					background-repeat: no-repeat;
					background-position: right 40px top 50%;
					min-width: 0;
					padding-right: 74px;
					padding-left: 37px;

					@media (max-width: 1100px){
						width: 100%;
						background-position: right calc(50% - 40px) top 50%;
					}

					&:hover,
					&:focus-visible{
						background-image: url(../images/arrow-right-pink.svg);
					}
				}
			}
		}

		.register-side{
			
			@media (min-width: 1100px + 1){
				padding-left: 68px;
			}

			@media (max-width: 1100px){
				
				.text-con{

					h6 em{
						text-decoration: underline;
					}
				}
			}

			&.success{

				.text-con{
					display: none;
				}

				.gform_confirmation_wrapper{
					margin-top: 0;
					text-align: left;

					.gform_confirmation_message{
						text-align: left;
						@include font-size(24);
						@include line-height(24,28);
						color: $colour;

						strong{
							display: block;
							margin-bottom: 1em;
						}

						a{
							color: $green;
							font-weight: 700;

							&:hover,
							&:focus-visible{
								color: $green-light;
							}
						}

						h4{
							color: $green;
						}

						br{
							display: none;
						}
					}
				}
			}

			@media (max-width: 1100px){
				margin-bottom: 0;

				h6{
					cursor: pointer;
				}

				&.open{

					.text-con{

						p{
							max-height: 9999px;
							overflow: hidden;
						}
					}
	
					.gform_wrapper{
						max-height: 9999px;
						overflow: hidden;
					}
				}
				
				.text-con{

					p{
						max-height: 0;
						overflow: hidden;
					}
				}

				.gform_wrapper{
					max-height: 0;
					overflow: hidden;
				}
			}

			.gform_wrapper .gfield.gfield--width-half{

				@media (max-width: 1100px){
					width: 100%;
				}
			}
		}

		.under-line{
			background-image: url(../images/bottom-line.svg);
			background-repeat: no-repeat;
			background-size: 100% 4.588px;
			height: 4.588px;
			width: 100%;
		}
	}

	.gform-body{
		background-color: white;
		border-radius: 40px;
		padding: 58px 69px 62px;
		margin-bottom: 180px;

		@media (max-width: $b3){
			padding: 28px 32px 62px;
			margin-bottom: 59px;
		}
	}

	h6{
		font-weight: 700;
		margin-bottom: .56em;
	}

	p{
		@include font-size(18);
		@include line-height(18,28);
		margin-bottom: 1.75em;
	}

	.top{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-image: url(../images/bottom-line.svg);
		background-repeat: no-repeat;
		background-size: 100% 4.588px;
		background-position: 50% 100%;
		padding-bottom: 27px;
		width: 100%;
		margin-bottom: 1.9em;

		@media (max-width: $b2){
			flex-direction: column;
			padding-bottom: 34px;
		}

		h4{
			color: $green;
			margin-bottom: 0;
			@include line-height(42,52);

			@media (max-width: $b3){
				margin-bottom: .1em;
				text-align: center;
			}
		}

		a{
			font-family: $alt-font;
			font-weight: 700;
			color: $green;
			@include font-size(24);
			background-image: url(../images/require.svg);
			background-position: 0 50%;
			padding-left: 42px;
			background-repeat: no-repeat;
			line-height: 37.333px;

			&:hover,
			&:focus-visible{
				color: $pink;
			}
		}
	}

	#gform_1{

		.text-con{

			@media (max-width: $b3){
				text-align: center;
			}
		}

		h6{
			margin-bottom: .8em;

			@media (max-width: $b2){
				@include font-size(24);
				@include line-height(24,28);
				margin-bottom: 1.1em;
			}
		}

		p{
			@include font-size(20);
			@include line-height(20,28);
			margin-bottom: 1.1em;

			@media (max-width: $b2){
				@include font-size(18);
				@include line-height(18,28);
			}
		}
	}

	.gform_page_fields{
		background-image: url(../images/bottom-line.svg);
		background-repeat: no-repeat;
		background-size: 100% 4.588px;
		background-position: 50% 100%;
		padding-bottom: 70px;
		margin-bottom: 38px;
		position: relative;

		&:after{
			content: '*Mandatory fields';
			@include font-size(18);
			font-weight: 600;
			position: absolute;
			bottom: 24px;
			left: 0;
			color: #7F7F7F;
		}
	}

	.gform_page_footer{
		display: flex;
		justify-content: space-between;
		align-items: center;

		.gform_previous_button{
			@extend %btn;
			width: auto;
			background-image: url(../images/arrow-left-light.svg);
			background-repeat: no-repeat;
			background-position: left 0 top 50%;
			min-width: 0;
			padding-right: 0;
			padding-left: 37px;
			background-color: transparent;
			box-shadow: none;
			border-color: white;
			color: $green!important;

			@media (max-width: $b3){
				background-position: left calc(50% - 30px) top 50%;
			}

			&:hover,
			&:focus-visible{
				box-shadow: none;
				background-color: transparent;
				border-color: white;
				color: $green-light!important;
			}
		}

		.gform_next_button{
			@extend %btn;
			width: auto;
			background-image: url(../images/arrow-right-white.svg);
			background-repeat: no-repeat;
			background-position: right 40px top 50%;
			min-width: 0;
			padding-right: 74px;
			padding-left: 37px;
			
			&:hover,
			&:focus-visible{
				background-image: url(../images/arrow-right-pink.svg);
			}

			// @media (max-width: $b3){
			// 	width: 100%;
			// 	background-position: right calc(50% - 40px) top 50%;
			// }

			@media (max-width: 414px){
				background-position: right calc(50% - 30px) top 50%;
				padding-right: 43px;
				padding-left: 7px;
			}
		}
					
		@media screen and (max-width: 500px) {
				
			input[type="submit"] {
				min-width: 110px;
			}
		}
	}

	.gfield--width-half + .radio-field{
		margin-top: 13px;
	}

	em{
		font-style: normal;
		color: $green;
	}
	
	.radio-field{
		display: flex;
		flex-wrap: wrap;

		.gfield_label{
			width: calc(100% - 195px);
			float: left;
			padding-right: 50px;
			margin-bottom: 24px;
			min-height: 2.33334em;

			@media (max-width: $b3){
				width: 100%;
				float: none;
				text-align: center;
				padding-right: 0;
			}

			.gfield_required{
				display: none;
			}
		}

		.ginput_container_radio{
			width: 195px;

			@media (max-width: $b3){
				width: 100%;
				justify-content: center;
			}
		}
	}

	#gform_page_1_1{

		.gform_page_footer{
			justify-content: flex-end;


		}

		.ginput_container_radio{

		}
	}

	#field_1_38{

		.gfield_label{
			@include font-size(42);

			@media (max-width: $b3){
				text-align: center;
			}
		}

		#input_1_38{
			justify-content: space-between;
		}

		.gchoice{
			width: calc(50% - 14px);
			margin: 0 0 1em;

			@media (max-width: $b2){
				width: 100%;
			}

			input[type="radio"]{

				&:checked{

					+ label{

						.are-con{
							background-color: $green;
							box-shadow: 21px 19px 0 $green-light;
	
							*{
								color: $green-light;
							}

							.svg-side{
	
								svg{
	
									*{
										fill: $green-light;
									}
								}
							}
						}
					}
				}

				+ label{
					padding: 0!important;
					width: 100%;
					margin-bottom: 1.7em;

					@media (max-width: $b2){
						margin-bottom: 1.6em;
					}

					*{
						transition: $time;
					}

					&:before,
					&:after{
						display: none;
					}

					.are-con{
						border-radius: 21px;
						background-color: $green-light;
						box-shadow: 21px 19px 0 $green;
						margin-right: 21px;
						display: flex;
						flex-wrap: wrap;
						padding: 39px 21px 20px;
						min-height: 232.48px;

						&:hover,
						&:focus-visible{
							background-color: $green;
							box-shadow: 21px 19px 0 $green-light;
	
							*{
								color: $green-light;
							}

							.svg-side{
	
								svg{
	
									*{
										fill: $green-light;
									}
								}
							}
						}

						@media (max-width: $b2){
							padding: 59px 18px 66px;

							svg{
								max-width: 86px;
							}
						}

						*{
							color: $green;
						}

						.svg-side{
							width: 34%;
							margin-right: 3.7%;
							padding-top: 3px;

							@media (max-width: $b2){
								width: 100%;
								margin-right: 0;
							}

							svg{
								margin: 0 auto;

								@media (max-width: $b2){
									margin-bottom: .4em;
								}

								*{
									fill: $green;
								}
							}
						}

						.info-side{
							width: 62.3%;

							@media (max-width: $b2){
								width: 100%;
								text-align: center;
							}

							.title{
								@include font-size(32);
								@include line-height(32,38);
								font-family: $alt-font;
								margin-bottom: .2em;
							}

							.text{
								@include font-size(20);
								@include line-height(20,28);
								font-weight: 600;
								width: 210px;

								@media (max-width: $b2){
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}

.form-section{

	@media (max-width: $b2){
		margin-top: 40px;
	}
	
	.gform-body{
		background-color: transparent;
		border-radius: 0;
		padding: 0;
		margin-bottom: 0;
	}

	.gform_wrapper{
		background-color: white;
		border-radius: 40px;
		padding: 58px 69px 62px;
		margin-bottom: 180px;

		@media (max-width: $b3){
			padding: 28px 32px 62px;
			margin-bottom: 59px;
		}
	}
}

.text-section{
	padding-top: 66px;
	padding-bottom: 181px;
}

@media (max-width: $b2){
	
	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: 24px;
		padding-left: 24px;
	}

	.row{
		margin-right: -24px;
		margin-left: -24px;
	}
}

.four-section{
	padding-top: 100px;
	padding-bottom: 100px;
	text-align: center;

	h1{
		color: $green;
	}
}