@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Regular.woff2') format('woff2'),
		url('../fonts/OpenSans-Regular.woff') format('woff'),
		url('../fonts/OpenSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Iskra';
	src: url('../fonts/Iskra-Regular.woff2') format('woff2'),
		url('../fonts/Iskra-Regular.woff') format('woff'),
		url('../fonts/Iskra-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Iskra';
	src: url('../fonts/Iskra-Medium.woff2') format('woff2'),
		url('../fonts/Iskra-Medium.woff') format('woff'),
		url('../fonts/Iskra-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Iskra';
	src: url('../fonts/Iskra-Bold.woff2') format('woff2'),
		url('../fonts/Iskra-Bold.woff') format('woff'),
		url('../fonts/Iskra-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Verveine';
	src: url('../fonts/Verveine-Regular.woff2') format('woff2'),
		url('../fonts/Verveine-Regular.woff') format('woff'),
		url('../fonts/Verveine-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@mixin bp-lg {
  @media (min-width: 992px) {
    @content;
  }
}

//BREAK POINTS
$b1:				1202px - 1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				769px - 1px; //769px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				480px - 1px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS

$red:				#c30007;

$green-light:		#C7ED6B;
$green:				#4CA67B;

$blue-back:			#EDF5FD;
$blue-border:		#EBF3FB;
$blue-form:			#F8FAFC;

$grey-form:			#A1A3A4;

$pink:				#E0548B;

$orange:			#EBB63F;

$yellow:			#F1E752;

$white:				#ffffff;
$black:				#000000;

$colour: 			$black;
$main-bg-colour:	$blue-back;

$font-open:			'Open Sans', serif;
//regular - 400

$font-iskra:		'Iskra', serif;
//regular - 400
//medium - 500
//bold - 700

$font-verv:			'Verveine', serif;
//regular - 400

$main-font:			$font-open;
$alt-font:			$font-iskra;
$alt-font2:			$font-verv;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	31px !default;

//!!PAGE WIDTH
$base-width:		1202px;

//!!SITE TRANSITIONS
$time:				.25s;