//<div id="burger" class="burger clearfix">menu
//	<div class="line1"></div>
//</div>

$outerWidth: 43.8px;
$outerHeight: 43.8px;

$lineHeight: 6.43px;
$lineWidth: 43.8px;
$gap: 6.7px;
$radius: 0px;

$outerWidthM: 43.8px;
$outerHeightM: 43.8px;

$lineHeightM: 6.43px;
$lineWidthM: 43.8px;
$gapM: 6.7px;
$radiusM: 0px;

$topLineColour: transparent;
$topLineColourOpen: transparent;

$middleLineColour: transparent;
$middleLineColourOpen: transparent;

$bottomLineColour: transparent;
$bottomLineColourOpen: transparent;

//@media (max-width: $b2){
//
//	.main-wrapper{
//
//		section{
//			overflow: hidden;
//			max-width: 375px;
//		}
//	}
//}

.burger{
	height: $outerHeight;
	width: $outerWidth;
	border-radius: 0;
	line-height: $outerHeight;
	padding: 0;
	-webkit-perspective: 900000px;
	perspective: 900000px;
	z-index: 1;
	pointer-events: auto;
	position: relative;
	transition: $time;
	font-weight: 400;
	display: none;

	@media (max-width: $b2){
		height: $outerHeightM;
		width: $outerWidthM;
		line-height: $outerHeightM;
		display: block;
		z-index: 3;
	}

	&:hover{
		cursor: pointer;
	}

	.line1{
		width: $lineWidth;
		max-width: 100%;
		height: $lineHeight;
		background-color: $middleLineColour;
		position: absolute;
		top: (calc($outerHeight / 2) - calc($lineHeight / 2));
		left: 50%;
		border-radius: $radius;
		transform: translateX(-50%);
		background-position: center center;
		background-image: url(../images/b1.svg);
		background-repeat: no-repeat;

		@media (max-width: $b2){
			width: $lineWidthM;
			height: $lineHeightM;
			top: (math.div($outerHeightM, 2) - math.div($lineHeightM, 2));
		}

		&:before,
		&:after{
			width: $lineWidth;
			max-width: $lineWidth;
			height: $lineHeight;
			position: absolute;
			content: '';
			left: 0px;
			border-radius: $radius;
			transform-origin: center center;
			background-position: center center;
			transition: $time;

			@media (max-width: $b2){
				width: $lineWidthM;
				height: $lineHeightM;
				border-radius: $radiusM;
			}
		}

		&:before{
			transform: translateY(-#{$gap + $lineHeight});
			background-color: $topLineColour;
			background-image: url(../images/b2.svg);
			background-repeat: no-repeat;

			@media (max-width: $b2){
				transform: translateY(-#{$gapM + $lineHeightM});
			}
		}

		&:after{
			transform: translateY(#{$gap + $lineHeight});
			background-color: $bottomLineColour;
			background-image: url(../images/b3.svg);
			background-repeat: no-repeat;

			@media (max-width: $b2){
				transform: translateY(#{$gapM + $lineHeightM});
			}
		}
	}

	&.close{

		&:before{
			transition: color .1s .25s;
		}

		.line1{
			background-color: $middleLineColour;
			transition: visibility .1s .2s, background-color .1s .2s, background-image .1s .2s;

			&:before{
				transform: translateY(-#{$gap + $lineHeight});
				background-color: $topLineColour;
				visibility: visible;
				animation: before .5s linear;
				transition: all $time 0s, visibility .1s .25s, background-color .1s .25s;

				@media (max-width: $b2){
					transform: translateY(-#{$gapM + $lineHeightM});
					animation: beforeM .5s linear;
				}
			}

			&:after{
				transform: translateY(#{$gap + $lineHeight});
				background-color: $bottomLineColour;
				visibility: visible;
				animation: after .5s linear;
				transition: all $time 0s, visibility .1s .25s, background-color .1s .25s;

				@media (max-width: $b2){
					transform: translateY(#{$gapM + $lineHeightM});
					animation: afterM .5s linear;
				}
			}

			@keyframes before {
				0% {transform: rotate(-45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(-#{$gap + $lineHeight});}
			}

			@keyframes after {
				0% {transform: rotate(45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(#{$gap + $lineHeight});}
			}

			@keyframes beforeM {
				0% {transform: rotate(-45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(-#{$gapM + $lineHeightM});}
			}

			@keyframes afterM {
				0% {transform: rotate(45deg) translateY(0);}
				33% {transform: rotate(0deg) translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: translateY(#{$gapM + $lineHeightM});}
			}
		}
	}

	&.open{

		.line1{
			transform-origin: 50% 50%;
			transition: visibility .1s .25s, background-color .1s .25s, background-image .1s .25s;
			background-color: $middleLineColourOpen;
			background-image: none;

			&:before{
				transform: rotate(45deg) translateY(0);
				background-color: $topLineColourOpen;
				visibility: visible;
				animation: before-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;

				@media (max-width: $b2){
					animation: before-openM .5s linear;
				}
			}

			&:after{
				transform: rotate(-45deg) translateY(0);
				background-color: $bottomLineColourOpen;
				visibility: visible;
				animation: after-open .5s linear;
				transition: visibility .1s .25s, background-color .1s .25s;
				
				@media (max-width: $b2){
					animation: after-openM .5s linear;
				}
			}

			@keyframes before-open {
				0% {transform: translateY(-#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(45deg) translateY(0);}
			}

			@keyframes after-open {
				0% {transform: translateY(#{$gap + $lineHeight});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(-45deg) translateY(0);}
			}

			@keyframes before-openM {
				0% {transform: translateY(-#{$gapM + $lineHeightM});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(45deg) translateY(0);}
			}

			@keyframes after-openM {
				0% {transform: translateY(#{$gapM + $lineHeightM});}
				33% {transform: translateY(0);}
				66% {transform: translateY(0);}
				100% {transform: rotate(-45deg) translateY(0);}
			}
		}
	}
}
