 html{
	font-size: 62.5%;
	font-family: $main-font;
	font-weight: 400;
	margin-top: 0 !important;
	overflow-x: hidden;

	&#BTT{
		margin-top: 0 !important;
	}

	// &.menu-open{
	// 	overflow: hidden;
}

body{
	color: $colour;
	background-color: $main-bg-colour;
	@include font-size(20);
	@include line-height(20,28);
	font-weight: 400;
	position: relative;
	overflow: hidden;

	// @media (max-width: $b2){
	// 	padding-top: 237px;
	// }

	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	*{

		&::-webkit-scrollbar {
			display: none;
		}

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}

#wpadminbar{
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
blockquote,
pre,
table,
li{
	color: $colour;
	margin-top: 0;
	font-weight: 400;
	margin-bottom: 1.5em;
	@include font-size(20);
	@include line-height(20,28);

	sub, 
	sup{
		font-size: 60%;
	}

	a{
		font-size: inherit;
		text-decoration: underline;
		font-weight: inherit;
		color: $pink;

		&:hover{
			color: $yellow;
			text-decoration: underline;
		}
	}

	strong{
		font-weight: 700;
		color: inherit;
	}

	em{
		font-style: italic;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	color: $colour;
	font-family: $alt-font;
	margin-bottom: .45em;
	font-weight: 400;
}

h1{
	@include font-size(90);
	@include line-height(90,90);

	@media (max-width: $b2){
		@include font-size(42);
		@include line-height(42,52);
		font-weight: 700;
	}
}

h2{
	@include font-size(82);
	@include line-height(82,72);
	font-weight: 500;

	@media (max-width: $b2){
		@include font-size(68);
		@include line-height(68,72);
	}
}

h3{
	@include font-size(62);
	@include line-height(62,72);
	font-weight: 500;

	@media (max-width: $b2){
		@include font-size(52);
		@include line-height(52,62);
	}
}

h4{
	@include font-size(42);
	@include line-height(42,72);
	font-weight: 700;
}

h5{
	@include font-size(35);
	@include line-height(35,33);
}

h6{
	@include font-size(32);
	@include line-height(32,38);
}

p{
	@include font-size(20);
	@include line-height(20,28);

	&.large{
		@include font-size(32);
		@include line-height(32,38);
	}
}

mark{
	background-color: $pink;
	font-weight: 700;
	box-shadow: .05em 0 0 $pink, -.05em 0 0 $pink;
}

strong{
	font-weight: 700;
}

blockquote{
	@include font-size(44);
	@include line-height(44,44);
	letter-spacing: normal;
	font-family: $alt-font2;
	margin: 0 auto 1.8em;
	font-weight: 400;
	position: relative;
	padding: 0;
	font-style: normal;
	color: $colour;
	background-position: 50% 0;
	padding: 90px 79px 70px;
	position: relative;
	z-index: 1;

	@media (max-width: $b2){
		@include font-size(32);
		@include line-height(32,32);
		padding: 65px 65px 52px;
		margin-bottom: 3.1em;
	}

	&:before{
		content: '“';
		position: absolute;
		top: .12em;
		left: .08em;
		font-size: 647%;
		line-height: 1;
		color: rgba(white,.2);
	}

	+ .by{
		padding: 0px 79px 0px 119px;
		background-image: url(../images/by-line.svg);
		background-repeat: no-repeat;
		background-position: 79px 50%;
		margin-bottom: 2.6em;
	}

	p{
		font-size: inherit;
		line-height: inherit;
		font-family: inherit;
		margin-bottom: 0!important;
	}
}

a{
	font-size: inherit;
	color: $pink;
	font-weight: 400;
	transition: $time;
	cursor: pointer;
	text-decoration: none;

	*{
		transition: $time;
	}

	&:hover{
		color: $yellow;
		text-decoration: none;
	}
}

hr{
	border: 0;
	width: 48px;
	height: 2px;
	margin: 1.74em auto 1.74em;
	background-color: $colour;
}

address{
	font-style: normal;
	@include font-size(20);
	color: $colour;
	font-weight: 400;
	margin-bottom: 1.45em;
}

ol,
ul,
dl{
	margin: 0;
	padding-left: 0;
	list-style-position: inside;
	@include font-size(0);
	margin-bottom: 14px;

	li,
	dt,
	dd{
		@include font-size(20);
		@include line-height(24,41);
		margin-bottom: 0;

		@media (max-width: $b2){
			@include font-size(15);
		}

		a{
			font-size: inherit;
		}
	}

	dd{
		margin-bottom: 20px;
	}
}

.acf_content ol,
.acf_content ul,
.acf_content dl,
main ol,
main ul,
main dl{
	@include font-size(20);
	margin-bottom: 1.7em;
}

ul{
	list-style-position: inside;
	padding-left: 0;

	&.no-bullet{
		list-style: none;

		li{
			padding-left: 0;

			&:before{
				display: none;
			}
		}
	}

	ul{

		li{

			&:before{
				background-color: transparent;
			}
		}
	}

	li{
		padding-left: .7em;
		list-style: none;
		position: relative;
		margin-bottom: .5em;

		.white &{

			&:before{
				background-color: $white;
			}
		}

		&:before{
			content: '';
			position: absolute;
			left: 0;
			top: .7em;
			width: .3em;
			height: .3em;
			border-radius: .3em;
			background-color: $colour;
		}

		li{

			&:before{
				background-color: transparent;
				border: 1px solid $colour;
			}
		}
	}
}

ul.gfield_checkbox,
ul.gfield_radio,
ul.gform_fields,
ul.sticky-menu,
ul.filter-items,
ul.woocommerce-PaymentMethods,
ul.woocommerce-shipping-methods,
ul.woocommerce-order-overview,
ul.wc_payment_methods,
ul.select2-results__options,
.woocommerce-MyAccount-navigation ul,
ul.woocommerce-error,
ul.matching-items,
ul.post-categories,
ul.tabs,
ul.small-menu,
ul.main-menu,
ul.footer-menu,
ul.sub-menu,
ul.g_fields,
ul.slick-dots,
ul.social-menu,
ul.flex,
ul.slick{
	list-style: none;
	padding-left: 0;
	padding-top: 0;

	> li{
		padding-left: 0;
		margin-bottom: 0;

		&:before,
		&:after{
			display: none;
		}
	}
}

ol:not(.pagination){
	list-style-position: outside;
	padding-left: 0px;
	counter-reset: section;

	li{
		padding-left: 1em;
		list-style: none;
		position: relative;
		margin-bottom: .5em;

		&:before{
			counter-increment: section;
			content: counter(section)".";
			position: absolute;
			right: calc(100% - 1em);
		}

		ol:not(.pagination){
			padding-left: 1.05em;
			margin-bottom: 2px;
		}
	}
}

table{
	width: 100%;
	background-color: transparent;
}

table,
table tr,
table th,
table td{
	border: none;
	border-collapse: collapse;
	padding: 6.8px 5px;
	@include font-size(20);
	@include line-height(20,28);
	line-height: 1.2;
	vertical-align: middle;
	text-align: center;
	font-weight: 400;
	color: $colour;

	tr{
		height: auto!important;
		padding: 0;
	}

	th{
		padding: 11px 5px;
		font-weight: 400;
		font-family: $main-font;
		color: $colour;
	}

	th,
	td{
		width: auto!important;
		height: auto!important;
	}
}

html#BTT body table{
	margin-bottom: 1em;
	border: none;
	padding: 10px 0;

	thead th{
		border: 1px solid $colour;
	}

	tbody td{
		border-bottom: 1px solid $colour;
		border-right: 1px solid $colour;

		&:first-of-type{
			border-left: 1px solid $colour;
			text-align: left;
		}
	}
}

.wp-caption{

	.wp-caption-text{
		@include font-size(15);
		font-weight: 700;
		color: $colour;

		@media (max-width: $b3){
			padding-left: 22px;
		}

		&:last-of-type:last-of-type{
			margin-bottom: 0;
			padding-top: 14px;
		}
	}
}

svg,
p.p-img a,
.wp-caption,
video,
img{
	max-width: 100%;
	width: auto;
	height: auto;
	display: block;

	&.alignnone{
		margin: 2.45em 0 3.15em;
	}

	&.alignright {
		float:right;
		margin: 2.45em 0 3.15em 2.45em;
	}

	&.alignleft {
		float:left;
		margin: 2.45em 2.45em 3.15em 0;
	}

	&.aligncenter {
		clear: both;
		display: block;
		margin: 2.45em auto 3.15em;
	}
}

a img.alignright {
	float:right;
	margin: 2.45em 0 3.15em 2.45em;
}

a img.alignleft {
	float:left;
	margin: 2.45em 2.45em 3.15em 0;
}

a img.aligncenter {
	clear: both;
	display: block;
	margin: 2.45em auto 3.15em;
}

::selection {
	background: lighten($green, 7.5%);
	color: white;
}

::-moz-selection {
	background: lighten($green, 7.5%);
	color: white;
}

.fieldset{
	margin-bottom: 0;

	.field{
		position: relative;
		padding-bottom: 1px;
	}

	div.mage-error[generated]{
		position: absolute;
		bottom: 5px;
	}
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input:focus-visible,
textarea:focus-visible,
select:focus-visible,
input[name][name][name]:focus-visible,
textarea[name][name][name]:focus-visible,
select[name][name][name]:focus-visible{
	border-color: $green!important;
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
input.mage-error:focus-visible,
select.mage-error:focus-visible,
textarea.mage-error:focus-visible,
.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
li.gfield.error input,
.gfield_error input,
.gfield_error select,
.gfield_error .dk-select,
.gfield_error textarea,
input.mage-error,
select.mage-error,
textarea.mage-error{
	border-color: $red!important;
}

div.mage-error[generated] {
	color: $red;
	position: absolute;
	margin-top: 4px;
	line-height: 1.1em;
	padding-top: 2px;
	padding-left: 14px;
	@include svg_img("../images/error-arrow");
	background-position: 0 0;
	margin-left: -2px;
	text-align: left;
}

.browsehappy{
	position: fixed;
	top: 0;
	left: 50%;
	z-index: 999999999;
	background-color: white;
	margin-bottom: 0;
	width: 540px;
	margin-left: -270px;
	text-align: center;
	padding: 4px 2px;
	font-size: 13px;
	color: black;
	border-radius: 0 0 4px 4px;
	line-height: 13px;
	font-weight: 400;

	span{
		background-image: url(../images/browserhappy-cross.png);
		background-repeat: no-repeat;
		height: 13px;
		width: 13px;
		@include inline;
		vertical-align: middle;
		background-position: 50% 50%;

		&:hover{
			background-color: #f96059;
			transition: $time;
		}
	}

	strong{
		font-weight: 700;
	}

	a{
		color: $red;
		text-decoration: none;
		font-weight: 400;

		&:hover{
			color: $red;
			text-decoration: underline;
		}
	}
}

.ta{
	display: table;
	width: 100%;
	height: 1px;
}

.tc{
	display: table-cell;
	vertical-align: middle;
}

.flex{
	display: flex;
	flex-wrap: wrap;

	&.center{
		align-items: center;
		justify-content: center;
	}

	&.top{
		align-self: flex-start;
	}

	&.bottom{
		align-self: flex-end;
	}

	&.no-wrap{
		flex-wrap: nowrap;
	}
}

pre{
	@include font-size(10);
}
