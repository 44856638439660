  //!!FORM
::-webkit-input-placeholder {
	@include font-size(18);
	color: $grey-form;
}
 
:-moz-placeholder { /* Firefox 18- */
	@include font-size(18);
	color: $grey-form;
}

::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(18);
	color: $grey-form;
}

:-ms-input-placeholder {
	@include font-size(18);
	color: $grey-form;
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 33px;
$label-bottom-margin: 30px;

input,
input.input-text,
textarea,
select{
	display: block;
	padding: 16px 16px;
	@include font-size(18);
	font-weight: 400;
	color: $grey-form;
	font-family: $main-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: $input-bottom-margin;
	border: 2px solid $blue-border;
	background-color: $blue-form;
	border-radius: 10px;
	line-height: 1.3em;
	-webkit-appearance: none;
	box-shadow: none;
}

textarea{
	height: 148px;
	min-height: 148px;
	min-width: 100%;

	@media (max-width: $b3){
		height: 148px;
		min-height: 148px;
	}
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
}

input:focus-visible,
input.input-text:focus-visible,
textarea:focus-visible,
select:focus-visible{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

[type="file"]{
	border: none;
	padding: 0;
}

//
//!!SELECT START
//
select{
	padding-right: 45px;
	appearance: none;
	@include svg_img("../images/select-icon");
	background-position: 100% 50%;
	line-height: 55.4px;
	height: 59.4px;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;

	&::-ms-expand {
		display: none;
	}

	option{
		padding-top: 5px;
		padding-bottom: 5px;
		color: $colour;
	}
}
//
//!!SELECT END
//

legend,
label{
	display: block;
	@include font-size(24);
	@include line-height(24,28);
	font-family: $alt-font;
	margin-bottom: $label-bottom-margin;
	transition: $time;
	font-weight: 700;
	color: $black;

	span{
		color: $green;
	}

	.gfield_required{
		
		&:after{
			content: '*';
			color: $green;
		}
		
		span{
			display: none;
		}
	}
}

*[type="submit"],
.submit-con [type="submit"]{
	@extend %btn;
	width: auto;
}

.submit-con{
	display: flex;
	justify-content: center;
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	& + span,
	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 45px!important;
		position: relative;
		cursor: pointer;
		@include font-size(20);
		line-height: 32.84px;
		@include inline;
		margin-bottom: 5px;
		font-style: normal;
		color: $colour;
		font-weight: 600;
		text-transform: none;
		text-align: left!important;
		font-family: $main-font;

		a{
			color: $pink;

			&:hover,
			&:focus-visible{
				color: $yellow;
			}
		}

		&:before{
			position: absolute;
			content: "";
			width: 32.84px;
			height: 32.84px;
			left: 0px;
			top: 0px;
			border-radius: 5px;
			background-color: $blue-form;
			border: 2px solid $blue-border;

		}

		&:after{
			position: absolute;
			content: "";
			width: 32.84px;
			height: 32.84px;
			left: 0px;
			top: 0px;
			border-radius: 5px;
			background-color: transparent;
			background-image: url(../images/tick.svg);
			background-repeat: no-repeat;
			opacity: 0;
			transition: $time;
			border: 2px solid $green;
			background-position: 50% 50%;
		}
	}

	&:checked + label:after,
	&:checked + .wpcf7-list-item-label:after,
	&:checked + span:after,
	& + label.checked:after{
		opacity: 1;
	}
}

input[type="radio"]{

	& + span,
	& + .wpcf7-list-item-label,
	& + label{

		&:before,
		&:after{
			border-radius: 5px;
		}
	}
}

//
//!!RADIO & CHECKBOX END
//

.gform_wrapper{

	.gform_required_legend{
		display: none;
	}

	.gform_submission_error{
		text-align: center;
		@include font-size(18);
		line-height: 1.2;
		color: $red;
		display: none;
	}

	.gform_fields{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		&:after{
			display: none;
		}
	}

	.gform_footer{
		padding-top: 6px;

		[type="submit"]{
			width: auto;
			background-color: $green-light;
			border-color: $green-light;
			color: $green!important;
			box-shadow: 5px 7px 0 $green;

			@media (max-width: $b3){
				width: 100%;
			}

			svg{

				*{
					fill: $green!important;
				}
			}

			&.big{
				box-shadow: 13px 9px 0 $green;

				&:hover,
				&:focus-visible{
					box-shadow: 13px 9px 0 $green-light;
				}
			}

			&:hover,
			&:focus-visible{
				background-color: $green;
				border-color: $green;
				color: $green-light!important;
				box-shadow: 5px 7px 0 $green-light;

				svg{

					*{
						fill: $green-light!important;
					}
				}
			}
		}
	}

	.gfield_label{

	}

	.gfield{
		position: relative;
		width: 100%;

		&.gfield--width-half{
			width: calc(50% - 14px);

			@media (max-width: $b3){
				width: 100%;
			}
		}

		&.gfield--width-third{
			width: calc(33% - 14px);

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.validation_message{
			@include font-size(11);
			line-height: .9;
			position: absolute;
			bottom: 2px;
			font-weight: 600;
	
			&.gfield_validation_message{
				color: $red;
			}
		}
	}
	
	.instruction{
		display: none;
	}

	fieldset{
		padding: 0;
		border: 0;
		margin: 0;
	}

	.gfield_description:not(.validation_message){
		@include font-size(18);
		margin-top: -24px;
		margin-bottom: 24px;
	}

	.gfield_radio{
		display: flex;
		flex-wrap: wrap;

		@media (max-width: $b3){
			justify-content: center;
		}

		.gchoice{

			+ .gchoice{
				margin-left: 40px;
			}
		}
	}

	.ginput_container_checkbox,
	.ginput_container_radio{
		margin-bottom: $input-bottom-margin;
	}

	legend{
		width: 100%;
	}

	.gform_validation_container:last-of-type{
		max-height: 0;
		overflow: hidden;
	}

	#field_4_21 {

		.gfield_label {
			display: none;
		}
	}

	.ginput_container_creditcard {

		.ginput_full {

			.StripeElement {
				display: block;
				padding: 16px 16px;
				@include font-size(18);
				font-weight: 400;
				color: $grey-form;
				font-family: $main-font;
				width: 100%;
				max-width: 100%;
				margin-bottom: $input-bottom-margin;
				border: 2px solid $blue-border;
				background-color: $blue-form;
				border-radius: 10px;
				line-height: 1.3em;
				-webkit-appearance: none;
				box-shadow: none;
				height: auto;
			}
		}
	}
}

.gform_confirmation_wrapper{
	margin: 4em auto 0;
	width: 950px;

	.gform_confirmation_message{
		color: $colour;
		@include font-size(32);
		@include line-height(32,38);
		text-align: center;
		font-family: $alt-font;
		font-weight: 700;
		color: $green;

		.links{

			br{
				display: none;
			}
		}

		li,
		p{
			@include font-size(16);
		}
	}
}

.gform_ajax_spinner{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-shadow: 0 0 25px 25px $green-light;
	background-color: $green-light;
	border-radius: 50%;

}

.ui-datepicker{
	width: 345px;
	@include font-size(16);
	background-color: white;
	border: 2px solid $blue-border;
	margin-top: 5px;
	border-radius: 10px;
	font-weight: 400;
	z-index: 50!important;

	@media (max-width: $b3){
		width: 260px;
	}

	*{
		@include font-size(16);
		font-weight: 400;
		font-weight: 400;

		@media (max-width: $b3){
			@include font-size(12.5);
		}
	}

	.ui-datepicker-header{
		padding: 10px 10px 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;

		.ui-datepicker-prev,
		.ui-datepicker-next{
			width: 30px;
			height: 30px;
			background-repeat: no-repeat;
			background-position: 50% 50%;

			span{
				display: none;
			}
		}

		.ui-datepicker-prev{
			order: 1;
			background-image: url(../images/arrow-left.svg);
			background-position: 45% 50%;
		}

		.ui-datepicker-next{
			order: 3;
			background-image: url(../images/arrow-right.svg);
			background-position: 55% 50%;
		}

		.ui-datepicker-title{
			width: 100%;
			width: calc(100% - 80px);
			order: 2;

			select{
				border: 2px solid $blue-border;
				background-color: $blue-form;
				height: 42px;
				line-height: 42px;
				padding-left: 6px;
				margin-bottom: 10px;

				+ select{
					margin-bottom: 0;
				}
			}
		}
	}

	.ui-datepicker-calendar.ui-datepicker-calendar.ui-datepicker-calendar{
		margin-bottom: 0!important;
		margin-top: 10px;
		position: relative;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: $grey-form;
		}

		th{
			background-color: transparent;
		}

		td,
		th{
			padding: 5px 5px;
			text-align: center!important;
			width: calc(100% / 7)!important;
			color: $grey-form;
			border: none!important;
			border-right: 2px solid $blue-border!important;
			border-top: 2px solid $blue-border!important;

			&:last-of-type{
				border-right: none!important;
			}

			a{
				padding: 0;
				border: none;
				text-decoration: none;
				color: $grey-form;
			}

			&.ui-datepicker-other-month.ui-datepicker-other-month.ui-datepicker-other-month{
				pointer-events: none;
				color: rgba($grey-form, .15);
				background-color: rgba($blue-border, .75);
			}

			&.ui-datepicker-unselectable{
				color: rgba($grey-form, .15);
				pointer-events: none;
			}

			.ui-state-default{
				font-weight: 400;
			}

			&:hover,
			&:focus-visible{
				background-color: rgba($blue-border, .5);
			}

			&.ui-datepicker-current-day{
				background-color: $blue-border;

				*{
					color: $grey-form;
				}
			}	
		}

		td{
			cursor: pointer;
		}
		
		th{
			pointer-events: none;
			font-weight: 400;

			*{
				color: $grey-form;
			}
		}
	}
}